import styled from 'styled-components';

import { formatViewport } from 'shared/getPixelsToViewport';
import { toggleDarkModeStyles } from 'shared/theme';

export const Container = styled.div`
  padding: ${formatViewport(20)};
  border-radius: ${formatViewport(8)};
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${toggleDarkModeStyles('widgetBackground')};
  flex-wrap: wrap;
  overflow: hidden;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
