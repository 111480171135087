import { VisibilityHours } from '../components/Announcements/types';

export const HOURS_LIST = [
  { time: '6:00 AM', id: 0, hour: 6 },
  { time: '7:00 AM', id: 1, hour: 7 },
  { time: '8:00 AM', id: 2, hour: 8 },
  { time: '9:00 AM', id: 3, hour: 9 },
  { time: '10:00 AM', id: 4, hour: 10 },
  { time: '11:00 AM', id: 5, hour: 11 },
  { time: '12:00 PM', id: 6, hour: 12 },
  { time: '1:00 PM', id: 7, hour: 13 },
  { time: '2:00 PM', id: 8, hour: 14 },
  { time: '3:00 PM', id: 9, hour: 15 },
  { time: '4:00 PM', id: 10, hour: 16 },
  { time: '5:00 PM', id: 11, hour: 17 },
  { time: '6:00 PM', id: 12, hour: 18 },
  { time: '7:00 PM', id: 13, hour: 19 },
  { time: '8:00 PM', id: 14, hour: 20 },
  { time: '9:00 PM', id: 15, hour: 21 },
];

export const VISIBILITY_HOURS = [
  { time: 'Indefinite', hour: VisibilityHours.INDEFINITE },
  { time: '12 hours', hour: 12 },
  { time: '24 hours', hour: 24 },
  { time: '2 days', hour: 2 * 24 },
  { time: '3 days', hour: 3 * 24 },
  { time: '5 days', hour: 5 * 24 },
];
