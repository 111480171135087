import { FormHelperText, TextField } from '@mui/material';
import styled from 'styled-components';

import { colors } from 'shared/colors';
import { formatViewport } from 'shared/getPixelsToViewport';
import { toggleDarkModeStyles } from 'shared/theme';

const COMMON_INPUT_PLACEHOLDER = `
  align-items: center;
  color: ${colors.darkGray};
  display: flex;
  font-size: ${formatViewport(18)};
  line-height: ${formatViewport(23)};
  font-style: normal;
  font-weight: 400;
`;

const COMMON_INPUT_PADDINGS = `
    padding-left: ${formatViewport(15)}; 
    padding-right: ${formatViewport(24)};
    padding-top: ${formatViewport(8)};
    padding-bottom: ${formatViewport(8)};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${formatViewport(22)};
`;

export const SpinnerWrapper = styled.div`
  svg {
    width: ${formatViewport(18)};
    height: ${formatViewport(18)};
    path {
      stroke: ${toggleDarkModeStyles('black')};
    }
  }
`;

export const FullWidthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FilesUploadSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HelperText = styled(FormHelperText)`
  font-size: ${formatViewport(12)} !important;
  color: red !important;
`;

export const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${formatViewport(15)};
`;

export const SmallTitle = styled.div`
  color: ${toggleDarkModeStyles('white')};
  font-size: ${formatViewport(14)};
  font-weight: 500;
  line-height: ${formatViewport(18)};
`;

export const AnnouncementHeaderField = styled(TextField)`
  .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
    border: ${formatViewport(1)} solid ${toggleDarkModeStyles('white')};
  }

  & .MuiInputBase-input {
    ${COMMON_INPUT_PLACEHOLDER};
    ${COMMON_INPUT_PADDINGS};
    color: ${toggleDarkModeStyles('white')};
  }

  & .MuiInputBase-root > fieldset {
    border: ${formatViewport(1)} solid ${toggleDarkModeStyles('white')};
  }

  & .MuiFormHelperText-root {
    margin: 0;
    font-size: ${formatViewport(12)};
    color: red;
  }
`;

export const AnnouncementCopyTextArea = styled(TextField)`
  .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
    border: ${formatViewport(1)} solid ${toggleDarkModeStyles('white')};
  }

  & .MuiInputBase-root {
    ${COMMON_INPUT_PLACEHOLDER};
    ${COMMON_INPUT_PADDINGS};
    color: ${toggleDarkModeStyles('white')};
    & > .MuiOutlinedInput-notchedOutline {
      border: ${formatViewport(1)} solid ${toggleDarkModeStyles('white')};
    }
  }
  & .MuiFormHelperText-root {
    margin: 0;
    font-size: ${formatViewport(12)};
  }
`;

export const DisplayDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${formatViewport(8, 'vh')};
`;

export const CrossWrapper = styled.div``;
