import { useMutation } from 'react-query';

import { removePhotos } from '../api/removePhotos';
import { toFormData } from '../shared/toFormData';

export const useRemovePhotos = ({ onSuccess }: { onSuccess?: (response: unknown) => Promise<unknown> | void }) => {
  const { mutate, ...rest } = useMutation({
    mutationFn: removePhotos,
    onSuccess,
  });

  const deletePhotos = async (files: string[]) => {
    if (files.length === 0) return;
    const filesFormData = toFormData<string>(files, 'filenames');
    await mutate(filesFormData);
  };

  return {
    ...rest,
    deletePhotos,
  };
};
