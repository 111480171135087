import { TailSpin } from 'react-loader-spinner';

import WidgetBase from 'components/WidgetBase';

import useWidgetsContext from 'hooks/useWidgetsContext';

import evLogo from 'images/evLogo.png';

import { usePopupContext } from 'providers/PopupProvider/PopupProvider';

import {
  handleHideTile,
  resetFifthTile,
  resetFirstTile,
  resetFourthTile,
  resetSecondTile,
  resetSixthTile,
  resetThirdTile,
} from 'shared/Widget/tileHandler';
import { colors } from 'shared/colors';
import { Schema, schema as initialSchema } from 'shared/defaultWidgetsSchema';
import { Column, ColumnOptions, Row, WidgetType } from 'shared/types';

import { useSettings } from '../../providers/SettingsProvider/SettingsProvider';
import Settings from '../Settings';

import * as Styles from './MainPage.styles';

const MainPage = () => {
  const { onWidgetChange, isPMDataLoading, widgetsSchema, buildingName, footerLogo, isEditable, buildingLogo } =
    useWidgetsContext();
  const { onSetActiveTile } = usePopupContext();
  const { hideLogo } = useSettings();

  const [
    [firstRowFirstTile, firstRowSecondTile, firstRowThirdTile],
    [secondRowFirstTile, secondRowSecondTile, secondRowThirdTile],
  ] = widgetsSchema || initialSchema;
  const onSelectWidget = (
    type: WidgetType,
    columnOptions: ColumnOptions,
    column: Column,
    row: Row,
    resetTileCallback: (
      columnsOptions: ColumnOptions,
      onTileReset: (column: Column, row: Row, type: WidgetType, columnOptions: ColumnOptions) => void,
      schema: Schema,
    ) => void,
  ) => {
    resetTileCallback(columnOptions, onWidgetChange, widgetsSchema);
    onWidgetChange(column, row, type, columnOptions);
    onSetActiveTile([column, row]);
  };

  return (
    <Styles.Page>
      {isEditable ? <Settings /> : null}
      <Styles.FullHD>
        <Styles.Container>
          {isPMDataLoading ? (
            <Styles.SpinnerContainer>
              <TailSpin color={colors.main} />
            </Styles.SpinnerContainer>
          ) : (
            <Styles.TilesWrapper>
              <WidgetBase
                columnOptions={firstRowFirstTile.columnOptions}
                hideWidget={handleHideTile(Row.First, Column.First, widgetsSchema)}
                tileNumber={[Column.First, Row.First]}
                type={firstRowFirstTile.type}
                onOptionClicked={(type, columnOptions) =>
                  onSelectWidget(type, columnOptions, Column.First, Row.First, resetFirstTile)
                }
              />
              <WidgetBase
                columnOptions={firstRowSecondTile.columnOptions}
                hideWidget={handleHideTile(Row.First, Column.Second, widgetsSchema)}
                tileNumber={[Column.Second, Row.First]}
                type={firstRowSecondTile.type}
                onOptionClicked={(type, columnOptions) =>
                  onSelectWidget(type, columnOptions, Column.Second, Row.First, resetSecondTile)
                }
              />
              <WidgetBase
                columnOptions={firstRowThirdTile.columnOptions}
                hideWidget={handleHideTile(Row.First, Column.Third, widgetsSchema)}
                tileNumber={[Column.Third, Row.First]}
                type={firstRowThirdTile.type}
                onOptionClicked={(type, columnOptions) =>
                  onSelectWidget(type, columnOptions, Column.Third, Row.First, resetThirdTile)
                }
              />
              <WidgetBase
                columnOptions={secondRowFirstTile.columnOptions}
                hideWidget={handleHideTile(Row.Second, Column.First, widgetsSchema)}
                tileNumber={[Column.First, Row.Second]}
                type={secondRowFirstTile.type}
                onOptionClicked={(type, columnOptions) =>
                  onSelectWidget(type, columnOptions, Column.First, Row.Second, resetFourthTile)
                }
              />

              <WidgetBase
                columnOptions={secondRowSecondTile.columnOptions}
                hideWidget={handleHideTile(Row.Second, Column.Second, widgetsSchema)}
                tileNumber={[Column.Second, Row.Second]}
                onOptionClicked={(type, columnOptions) =>
                  onSelectWidget(type, columnOptions, Column.Second, Row.Second, resetFifthTile)
                }
                type={secondRowSecondTile.type}
              />
              <WidgetBase
                columnOptions={secondRowThirdTile.columnOptions}
                hideWidget={handleHideTile(Row.Second, Column.Third, widgetsSchema)}
                tileNumber={[Column.Third, Row.Second]}
                type={secondRowThirdTile.type}
                onOptionClicked={(type, columnOptions) =>
                  onSelectWidget(type, columnOptions, Column.Third, Row.Second, resetSixthTile)
                }
              />
            </Styles.TilesWrapper>
          )}
        </Styles.Container>
      </Styles.FullHD>
      <Styles.Footer>
        <Styles.BuildingName>
          {buildingLogo ? <Styles.BuildingLogo src={buildingLogo} alt="building logo" /> : buildingName}
        </Styles.BuildingName>
        {footerLogo ? (
          <Styles.LogoWrapper>
            <Styles.Logo src={footerLogo} />
          </Styles.LogoWrapper>
        ) : null}
        {hideLogo ? null : (
          <Styles.LogoContainer>
            <Styles.LogoText>powered by</Styles.LogoText>
            <Styles.EVLogo src={evLogo} />
          </Styles.LogoContainer>
        )}
      </Styles.Footer>
    </Styles.Page>
  );
};

export default MainPage;
