import { minutesToMilliseconds } from 'date-fns';

export const UPLOAD_IMAGES_OPTIONS: { value: number; text: string }[] = [
  { value: 2000, text: '2 seconds' },
  { value: 5000, text: '5 seconds' },
  { value: 30_000, text: '30 seconds' },
  { value: 60_000, text: '1 minute' },
  { value: minutesToMilliseconds(3), text: '3 minutes' },
  { value: minutesToMilliseconds(5), text: '5 minutes' },
  { value: minutesToMilliseconds(10), text: '10 minutes' },
];

export const DEFAULT_UPLOAD_IMAGE_OPTION = UPLOAD_IMAGES_OPTIONS[0].value;
