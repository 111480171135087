import React from 'react';

import { Cross } from '../../../images/svg';
import ImageRotationTime from '../../ImageRotationTime';
import Popup from '../Popup.component';
import { UPLOAD_IMAGES_OPTIONS } from '../UploadImagesPopup/constant';

import * as S from '../UploadImagesPopup/UploadImagesPopup.styles';

const PdfWidgetPopup = ({
  defaultDurationValue,
  open,
  setRotationDuration,
  onClose,
  onSubmit,
}: {
  defaultDurationValue: number;
  open: boolean;
  setRotationDuration: React.Dispatch<React.SetStateAction<number>>;
  onClose: () => void;
  onSubmit: () => void;
}) => {
  if (!open) {
    return null;
  }

  const onHandleSubmit = () => {
    onSubmit();
    onClose();
  };

  return (
    <Popup portalName="pdfs-portal">
      <S.Overlay>
        <S.ModalContent>
          <S.TopBarWrapper>
            <S.TopBarTitle>Add Automated Event Flyer</S.TopBarTitle>
            <S.CrossButton onClick={onClose}>
              <Cross />
            </S.CrossButton>
          </S.TopBarWrapper>
          <ImageRotationTime
            defaultValue={String(defaultDurationValue)}
            dropdownTitle="Change PDF every..."
            options={UPLOAD_IMAGES_OPTIONS}
            title="Displaying details"
            onSelect={setRotationDuration}
          />
          <S.FooterWrapper>
            <S.AddButton onClick={onHandleSubmit}>Add</S.AddButton>
          </S.FooterWrapper>
        </S.ModalContent>
      </S.Overlay>
    </Popup>
  );
};

export default PdfWidgetPopup;
