import React, { useEffect, useState } from 'react';

import { useRemovePhotos } from 'hooks/useRemovePhotos';
import { useSavePhotos } from 'hooks/useSavePhotos';
import { useUpdateTileInSchema } from 'hooks/useUpdateTileInSchema';
import useWidgetsContext from 'hooks/useWidgetsContext';

import { usePopupContext } from 'providers/PopupProvider/PopupProvider';

import { Column, ColumnOptions, Row } from 'shared/types';

import * as Styles from './styles';
import UploadImagesPopup from '../Popups/UploadImagesPopup';
import { DEFAULT_UPLOAD_IMAGE_OPTION } from '../Popups/UploadImagesPopup/constant';
import { UploadImagesProps } from '../Popups/UploadImagesPopup/types';
import { UploadImageFile } from '../UploadImages/types';
import { useGetNextImageIndex } from './hooks';
import { isSelectedTileEqualToCurrent } from './utils';

interface RotatingGalleryProps {
  tileNumber: [Column, Row];
  columnOptions: ColumnOptions;
}

const RotatingGalleryWidget = ({ tileNumber, columnOptions }: RotatingGalleryProps) => {
  const { widgetsSchema } = useWidgetsContext();
  const [column, row] = tileNumber;

  const { updateTile } = useUpdateTileInSchema();

  const [rotationDuration, setRotationDuration] = useState(
    widgetsSchema[row][column].rotatingGallery?.defaultDurationValue || DEFAULT_UPLOAD_IMAGE_OPTION,
  );

  const {
    onClose,
    open: { imageUploaderPopup },
    activeTile,
  } = usePopupContext();

  const [files, setFiles] = useState<UploadImageFile[]>(widgetsSchema[row][column].rotatingGallery?.files || []);

  const { savePhotos, isLoading: isSavingLoading } = useSavePhotos({
    onSuccess: (filesSavedInDB) => {
      setFiles([...files, ...filesSavedInDB]);
      onClose('imageUploaderPopup');
    },
  });

  const { deletePhotos, isLoading: isDeletingLoading } = useRemovePhotos({
    onSuccess: () => {
      onClose('imageUploaderPopup');
    },
  });

  // We can have multiple `RotatingGalleryWidget` components. Here we will only show popup for the selected component.
  const isSelectedRotatingGalleryCanBeOpen = activeTile
    ? isSelectedTileEqualToCurrent(activeTile, tileNumber) && imageUploaderPopup
    : imageUploaderPopup;

  const { activeIndex } = useGetNextImageIndex({
    disabled: isSelectedRotatingGalleryCanBeOpen,
    duration: rotationDuration,
    files,
  });

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [],
  );

  const renderImage = (file: UploadImageFile) => {
    if (!file) return;
    if (file.isPdf) {
      return (
        <Styles.PdfDocument file={file.preview}>
          <Styles.PdfPage pageNumber={1} renderMode="svg" />
        </Styles.PdfDocument>
      );
    }

    return <Styles.Image src={file.preview} />;
  };

  const onSave = async (uploadedFiles: UploadImageFile[], removedFilesIds: string[]) => {
    const filteredFiles = uploadedFiles.filter((file) => file instanceof File);
    await savePhotos(filteredFiles);
    await deletePhotos(removedFilesIds);
    setFiles(uploadedFiles);
  };

  useEffect(() => {
    const rotatingGallery: UploadImagesProps = {
      defaultDurationValue: rotationDuration,
      files,
    };
    updateTile(row, column, rotatingGallery, 'rotatingGallery');
  }, [files]);

  return (
    <>
      {isSelectedRotatingGalleryCanBeOpen ? (
        <UploadImagesPopup
          columnOptions={columnOptions}
          defaultDurationValue={rotationDuration}
          files={files}
          isLoading={isSavingLoading || isDeletingLoading}
          setRotationDuration={setRotationDuration}
          onClose={() => onClose('imageUploaderPopup')}
          onSave={onSave}
        />
      ) : null}
      <Styles.Container>
        <Styles.BannersList distance={files.length}>
          {files.map((file) => (
            <Styles.ImageWrapper key={file.preview} activeIndex={activeIndex}>
              {renderImage(file)}
            </Styles.ImageWrapper>
          ))}
        </Styles.BannersList>
      </Styles.Container>
    </>
  );
};

export default RotatingGalleryWidget;
