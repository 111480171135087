import { updateTileInSchema } from 'shared/Widget/tileHandler';
import { Tile } from 'shared/defaultWidgetsSchema';
import { Column, Row } from 'shared/types';

import useWidgetsContext from './useWidgetsContext';

export const useUpdateTileInSchema = () => {
  const { onWidgetSchemaChange, widgetsSchema } = useWidgetsContext();

  const updateTile = <T extends {}>(
    row: Row,
    column: Column,
    newTile: T,
    newTileName?: 'announcements' | 'automatedEventFlyerTime' | 'rotatingGallery',
  ) => {
    const newWidgetTile = newTileName ? { [newTileName]: newTile } : newTile;
    const currentTileSchema: Tile = { ...widgetsSchema[row][column], ...newWidgetTile };
    const updatedSchema = updateTileInSchema(currentTileSchema, row, column, widgetsSchema);
    onWidgetSchemaChange(updatedSchema);
  };

  return {
    updateTile,
  };
};
