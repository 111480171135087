import { useMutation } from 'react-query';

import { getPhotos } from '../api/getPhotos';
import { UploadImageFile } from '../components/UploadImages/types';
import { toFormData } from '../shared/toFormData';

export const useSavePhotos = ({ onSuccess }: { onSuccess: (files: UploadImageFile[]) => Promise<unknown> | void }) => {
  const addPhotos = useMutation({
    mutationFn: getPhotos,
    onSuccess: (response) => {
      const files: string[] = response.data.fileUrls;
      const transformFiles = files.map((url) => ({
        isPdf: url.toLowerCase().endsWith('.pdf'),
        preview: url,
      }));
      onSuccess(transformFiles as UploadImageFile[]);
    },
  });

  const savePhotos = async (files: File[]) => {
    if (files.length === 0) return;
    const filesFormData = toFormData(files, 'files');
    await addPhotos.mutate(filesFormData);
  };

  return {
    ...addPhotos,
    savePhotos,
  };
};
