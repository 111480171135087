import { Document, Page } from 'react-pdf';
import styled from 'styled-components';

import { colors } from 'shared/colors';
import { toggleDarkModeStyles } from 'shared/theme';

import { formatViewport } from '../../shared/getPixelsToViewport';

export const Container = styled.div`
  background-color: ${colors.white};
  border-radius: ${formatViewport(8)};
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

export const BannersList = styled.div<{ distance: number }>`
  background: ${toggleDarkModeStyles('widgetBackground')};
  display: inline-flex;
  flex-wrap: nowrap;
  height: 100%;
  position: relative;
`;

export const ImageWrapper = styled.div<{ activeIndex: number }>`
  flex-shrink: 0;
  height: 100%;
  transform: ${({ activeIndex }) => `translateX(-${activeIndex * 100}%);`}
  transition: transform 0.5s ease-in-out;
  width: 100%;
`;

export const Image = styled.img`
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

export const PdfDocument = styled(Document)`
  height: 100% !important;

  & > .react-pdf__Page {
    width: 100% !important;
    height: 100% !important;
  }

  & .react-pdf__Page__svg {
    display: flex !important;
    height: 100% !important;
    justify-content: center !important;
    width: 100% !important;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;

export const PdfPage = styled(Page)``;
