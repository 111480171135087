import { AnnouncementsFormValues } from '../components/Announcements/types';
import { UploadImagesProps } from '../components/Popups/UploadImagesPopup/types';
import { ColumnOptions, WidgetType } from './types';

export interface Tile {
  announcements: AnnouncementsFormValues[] | null;
  automatedEventFlyerTime: number | null;
  columnOptions: ColumnOptions | null;
  coversTilesIndexes: number[][];
  isCovered: boolean;
  isFullColumn: boolean;
  isHalfColumn: boolean;
  isHorizontalColumn: boolean;
  isSquareColumn: boolean;
  rotatingGallery: UploadImagesProps | null;
  type: WidgetType;
}

export type Schema = Tile[][];

export const schema: Schema = [];

const ROW = 2;
const COLUMN = 3;

for (let row = 0; row < ROW; row += 1) {
  const row = [];
  for (let column = 0; column < COLUMN; column += 1) {
    row.push({
      announcements: null,
      automatedEventFlyerTime: null,
      columnOptions: null,
      coversTilesIndexes: [],
      isCovered: false,
      isFullColumn: false,
      isHalfColumn: false,
      isHorizontalColumn: false,
      isSquareColumn: false,
      type: WidgetType.None,
      rotatingGallery: null,
    });
  }
  schema.push(row);
}
