import { ReactComponent as TooltipIcon } from 'images/svg/tooltip.svg';

import * as S from './styles';
import { TooltipProps } from './types';

const Tooltip = ({ label = 'tooltip' }: TooltipProps) => (
  <S.Wrapper>
    <S.TooltipIconWrapper>
      <TooltipIcon />
    </S.TooltipIconWrapper>
    <S.Label>{label}</S.Label>
  </S.Wrapper>
);

export default Tooltip;
