import { Schema } from 'shared/defaultWidgetsSchema';

import { axiosInstance } from '../axiosInstance';

export const updateTvAppSchema = async (locationId: string | undefined, schema: Schema, logo: string | null) => {
  if (!locationId) return;
  const { data } = await axiosInstance.put(`/tv-app/locationId/${locationId}/schema`, {
    tvAppSchema: JSON.stringify({ schema, logo }),
  });
  return data;
};
