import { ExcludedTransportModeType } from '../type';
import { Bus, Monorail, Plane, Subway, Train, Walk, busRapid } from './Svgs';

const IconsSvg: Record<ExcludedTransportModeType, () => JSX.Element> = {
  bus: Bus,
  lightRail: Train,
  highSpeedTrain: Train,
  intercityTrain: Train,
  interRegionalTrain: Train,
  cityTrain: Train,
  subway: Subway,
  privateBus: Bus,
  aerial: Plane,
  busRapid,
  monorail: Monorail,
  flight: Plane,
  walk: Walk,
  regionalTrain: Train,
};

export const Icons = ({ iconType }: { iconType: ExcludedTransportModeType }) => {
  const Component = IconsSvg[iconType || 'bus'];

  return <Component />;
};
