import { MenuItem } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { getHours } from 'date-fns';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';

import FormDropdown from 'components/FormDropdown';

import UseWidgetsContext from 'hooks/useWidgetsContext';

import { HOURS_LIST } from 'shared/hours.constant';
import { getUserDateInTimezone } from 'shared/timezones';

import * as S from './styles';
import { isDatesEqual } from '../../shared/isDatesEqual';
import { AnnouncementsFormValues } from '../Announcements/types';
import { HoursValue } from './types';

const FormDateAndTime = () => {
  const { timeZone } = UseWidgetsContext();
  const [openCalendar, setOpenCalendar] = useState(false);
  const formik = useFormikContext<AnnouncementsFormValues>();
  const { values, setFieldValue, setStatus, status } = formik;
  const { date, time } = values;

  const userSelectedDateHours = getHours(getUserDateInTimezone(date, timeZone));
  const minDate = getUserDateInTimezone(new Date(), timeZone);

  const filterHoursListBasedOnAvailableHour = () => {
    // Hint: check if user selected today
    if (isDatesEqual(date, minDate)) {
      return HOURS_LIST.filter(({ hour }) => hour > userSelectedDateHours);
    }
    return HOURS_LIST;
  };

  const hoursList = filterHoursListBasedOnAvailableHour();

  const getHoursValue = () => {
    if (time && time < userSelectedDateHours) {
      return hoursList[0];
    }
    return hoursList.find((hour) => hour.hour === time);
  };

  const hoursValue = getHoursValue();
  const value = hoursList.find((hour) => hour.hour === time)?.time || HoursValue.NO_HOURS;

  useEffect(() => {
    if (value === HoursValue.NO_HOURS) {
      setStatus('Please choose another date');
      setFieldValue('time', null);
    } else {
      setStatus(null);
    }
  }, [value]);

  const onDateChange = (date: Date) => {
    if (isDatesEqual(date, minDate) && value === HoursValue.NO_HOURS) {
      setFieldValue('time', null);
      setFieldValue('date', date);
      return;
    }
    setFieldValue('time', 6);
    setFieldValue('date', date);
  };

  return (
    <S.Column>
      <S.Wrapper>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <S.DatePickerWrapper
            defaultValue={minDate}
            format="EEEE MM/dd/yyyy"
            minDate={minDate}
            open={openCalendar}
            slots={{ inputAdornment: () => <S.CalendarIcon onClick={() => setOpenCalendar(true)} /> }}
            value={date}
            onClose={() => setOpenCalendar(false)}
            onChange={(date) => onDateChange(date as Date)}
          />
        </LocalizationProvider>
        <FormDropdown
          disabled={!hoursValue}
          value={value}
          renderOptions={hoursList.map(({ time, hour, id }) => (
            <MenuItem key={id} value={hour}>
              <S.ListItem primary={time} />
            </MenuItem>
          ))}
          onChange={(value) => setFieldValue('time', value)}
        />
      </S.Wrapper>
      <S.HelperText error={Boolean(status)}>{status}</S.HelperText>
    </S.Column>
  );
};

export default FormDateAndTime;
