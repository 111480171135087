const endpoints = {
  PROPERTY_MANAGER: '/propertymanager',
  CALENDAR_EVENTS: '/training-for-tv?limit=10',
  PDFS: '/training-pdfs?limit=10',
  EVENT_IMAGE: '/chitown-images/training',
  AVATAR: '/chitown-avatar/avatar',
  FEED_1ST_PART: '/post',
  FEED_2ND_PART: '?skip=0&limit=10',
  FEED_IMAGE: '/chitown-images/booking',
  WEATHER: '/weather',
  TV_APP_SCHEMA: (id: number | string) => `/building/${id}/tv-app-schema`,
  PERKS: '/neighbourhood',
  PERKS_IMAGE: '/chitown-images/neighbourhood',
  GROUPS: '/chitown-images/groups',
};

export default endpoints;
