import { Schema } from '../defaultWidgetsSchema';
import { ColumnOptions } from '../types';
import { isTileOfType } from './tileHandler';

export const HideTilesIf = {
  firstTileIsOpen: (schema: Schema) => isTileOfType(1, 0, schema, [ColumnOptions.SquareColumn]),
  secondTileIsOpen: (schema: Schema) => {
    if (isTileOfType(1, 0, schema, [ColumnOptions.SquareColumn])) {
      return true;
    }

    if (isTileOfType(1, 2, schema, [ColumnOptions.SquareColumn])) {
      return true;
    }

    if (isTileOfType(1, 1, schema, [ColumnOptions.SquareColumn])) {
      return true;
    }

    if (isTileOfType(0, 2, schema, [ColumnOptions.SquareColumn, ColumnOptions.HorizontalColumn])) {
      return true;
    }

    if (isTileOfType(0, 0, schema, [ColumnOptions.SquareColumn, ColumnOptions.HorizontalColumn])) {
      return true;
    }

    return false;
  },

  thirdTileIsOpen: (schema: Schema) =>
    isTileOfType(0, 1, schema, [ColumnOptions.SquareColumn, ColumnOptions.HorizontalColumn]),

  fourthTileIsOpen: (schema: Schema) =>
    isTileOfType(0, 0, schema, [ColumnOptions.SquareColumn, ColumnOptions.FullColumn]),

  fifthTileIsOpen: (schema: Schema) => {
    if (isTileOfType(1, 2, schema, [ColumnOptions.SquareColumn, ColumnOptions.HorizontalColumn])) {
      return true;
    }

    if (isTileOfType(0, 2, schema, [ColumnOptions.SquareColumn])) {
      return true;
    }

    if (isTileOfType(0, 0, schema, [ColumnOptions.SquareColumn])) {
      return true;
    }

    if (isTileOfType(1, 0, schema, [ColumnOptions.SquareColumn])) {
      return true;
    }

    if (isTileOfType(0, 1, schema, [ColumnOptions.FullColumn, ColumnOptions.SquareColumn])) {
      return true;
    }

    if (isTileOfType(1, 0, schema, [ColumnOptions.HorizontalColumn])) {
      return true;
    }

    return false;
  },

  sixthTileIsOpen: (schema: Schema) => {
    if (isTileOfType(0, 2, schema, [ColumnOptions.SquareColumn])) {
      return true;
    }

    if (isTileOfType(1, 1, schema, [ColumnOptions.SquareColumn])) {
      return true;
    }

    if (isTileOfType(0, 1, schema, [ColumnOptions.SquareColumn])) {
      return true;
    }

    if (isTileOfType(0, 2, schema, [ColumnOptions.FullColumn])) {
      return true;
    }

    if (isTileOfType(1, 1, schema, [ColumnOptions.HorizontalColumn])) {
      return true;
    }

    return false;
  },
};
