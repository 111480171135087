import { PerkData } from 'shared/types';

import { axiosInstance } from '../axiosInstance';

const getPerks = async (buildingId: number | null) => {
  if (!buildingId) return;
  const { data } = await axiosInstance.get<PerkData[]>(`/neighbourhood/building/${buildingId}`);
  return data;
};

export default getPerks;
