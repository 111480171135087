import { setHours, startOfDay } from 'date-fns';
import { FormikProvider, useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';

import AnnouncementsPopup from 'components/Popups/AnnouncementsPopup';
import { isSelectedTileEqualToCurrent } from 'components/RotatingGallery/utils';

import { usePopupContext } from 'providers/PopupProvider/PopupProvider';

import { Column, ColumnOptions, Row } from 'shared/types';
import { announcementsValidationSchema } from 'shared/yup-schemas';

import * as S from './styles';
import { useRemovePhotos } from '../../hooks/useRemovePhotos';
import { useSavePhotos } from '../../hooks/useSavePhotos';
import useWidgetsContext from '../../hooks/useWidgetsContext';
import { updateTileInSchema } from '../../shared/Widget/tileHandler';
import { Tile } from '../../shared/defaultWidgetsSchema';
import { extractIdFromUrl } from '../../shared/extractIdFromUrl';
import { updateAnnouncement } from '../Popups/AnnouncementsPopup/utils';
import FullColumnAnnouncement from './components/FullColumnAnnouncement';
import HalfColumnAnnouncement from './components/HalfColumnAnnouncement';
import { ANNOUNCEMENT_INITIAL_VALUES } from './constants';
import { AnnouncementsFormValues } from './types';

const Announcements = ({ tileNumber, columnOptions }: { tileNumber: [Column, Row]; columnOptions: ColumnOptions }) => {
  const { onWidgetSchemaChange, widgetsSchema, userData } = useWidgetsContext();
  const [column, row] = tileNumber;

  const [announcements, setAnnouncements] = useState<AnnouncementsFormValues[]>(
    widgetsSchema[row][column].announcements || [],
  );
  const [removedAnnouncements, setRemovedAnnouncements] = useState<string[]>([]);
  const id = useRef<number>(0);
  const selectedAnnouncementId = useRef<number | null>(null);
  const {
    activeTile,
    open: { announcementsPopup },
    onClose,
  } = usePopupContext();

  const { deletePhotos, isLoading: isDeletingLoading } = useRemovePhotos({});

  const { savePhotos, isLoading: isSavingLoading } = useSavePhotos({
    onSuccess: (filesSavedInDB) => {
      setAnnouncements((prevAnnouncements) =>
        prevAnnouncements.map((announcement, i) => {
          if (i === selectedAnnouncementId.current) {
            return {
              ...announcement,
              files: filesSavedInDB,
            };
          }
          return announcement;
        }),
      );
    },
  });

  const formik = useFormik<AnnouncementsFormValues>({
    enableReinitialize: true,
    initialValues: { ...ANNOUNCEMENT_INITIAL_VALUES, isEditMode: announcements.length > 0 },
    validationSchema: announcementsValidationSchema,
    onSubmit: async (formAnnouncement) => {
      const filteredFiles = formAnnouncement.files.filter((file) => file instanceof File);
      await savePhotos(filteredFiles);
      await deletePhotos(removedAnnouncements);
      const updatedDate = setHours(startOfDay(formAnnouncement.date), formAnnouncement.time || 6);
      const announcement = { ...formAnnouncement, date: updatedDate };
      if (announcement.isEditing) {
        setAnnouncements(updateAnnouncement(announcements, announcement));
        selectedAnnouncementId.current = announcement.id;
      } else {
        setAnnouncements((prevAnnouncements) => [...prevAnnouncements, { ...announcement, userData, id: id.current }]);
        selectedAnnouncementId.current = id.current;
        id.current += 1;
      }

      onClose('announcementsPopup');
      setRemovedAnnouncements([]);
      formik.resetForm();
    },
  });

  useEffect(() => {
    const currentTileSchema: Tile = { ...widgetsSchema[row][column], announcements };
    const updatedSchema = updateTileInSchema(currentTileSchema, row, column, widgetsSchema);
    onWidgetSchemaChange(updatedSchema);
  }, [announcements]);

  const isSelectedAnnouncementCanBeOpen = activeTile
    ? isSelectedTileEqualToCurrent(activeTile, tileNumber) && announcementsPopup
    : announcementsPopup;

  const onChangeAnnouncements = (newAnnouncements: AnnouncementsFormValues[]) => {
    setAnnouncements(newAnnouncements);
  };

  const renderAnnouncementBasedOnTileType = () => {
    if (columnOptions === ColumnOptions.HalfColumn) {
      return <HalfColumnAnnouncement announcements={announcements} onChangeAnnouncements={onChangeAnnouncements} />;
    }
    return <FullColumnAnnouncement announcements={announcements} onChangeAnnouncements={onChangeAnnouncements} />;
  };

  const onRemoveAnnouncements = async (id: number) => {
    const announcementToDelete = announcements.find((el) => el.id === id);
    if (announcementToDelete) {
      setAnnouncements((prevAnnouncements) =>
        prevAnnouncements.filter((prevAnnouncement) => prevAnnouncement.id !== id),
      );
      const filesIdsToRemove = announcementToDelete.files
        .map((file) => extractIdFromUrl(file.preview))
        .filter((id): id is string => id !== null);
      await deletePhotos(filesIdsToRemove);
    }
  };

  return (
    <FormikProvider value={formik}>
      <AnnouncementsPopup
        columnOptions={columnOptions}
        createdAnnouncements={announcements}
        open={isSelectedAnnouncementCanBeOpen}
        isLoading={isSavingLoading || isDeletingLoading}
        onRemoveAnnouncements={onRemoveAnnouncements}
        onRemoveFile={setRemovedAnnouncements}
        onClose={() => {
          onClose('announcementsPopup');
          formik.resetForm();
        }}
      />
      <S.AnnouncementsWrapper>
        {announcements.length > 0 ? renderAnnouncementBasedOnTileType() : <S.NoAnnouncementView />}
      </S.AnnouncementsWrapper>
    </FormikProvider>
  );
};

export default Announcements;
