import { Document, Page } from 'react-pdf';
import styled from 'styled-components';

import { colors } from 'shared/colors';
import { formatViewport } from 'shared/getPixelsToViewport';
import { toggleDarkModeStyles } from 'shared/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${formatViewport(20)};
  height: 100%;
  padding: ${formatViewport(10)};
  border-radius: ${formatViewport(8)};
`;

export const MainTitle = styled.div`
  color: ${toggleDarkModeStyles('gunmetal')};
  font-size: ${formatViewport(28)};
  font-weight: 500;
  line-height: ${formatViewport(35)};
`;

export const AnnouncementsList = styled.div`
  display: flex;
  gap: ${formatViewport(12)};
  height: 100%;
  width: 100%;
  overflow-x: auto;
  border-radius: ${formatViewport(6.2)};
`;

export const Announcement = styled.div<{ translateValue: number }>`
  background: ${colors.greenishGrey};
  border-radius: ${formatViewport(6.2)};
  box-shadow: 0 7px 14px rgba(0, 0, 0, 0.05);
  flex: 0 0 ${formatViewport(292)};
  padding: ${formatViewport(18)};
  transform: translateX(
    ${(props) => `calc(${formatViewport(props.translateValue * 292)} + ${formatViewport(props.translateValue * 12)})`}
  );
  transition: transform 0.5s ease;
`;

export const Image = styled.img`
  border-radius: ${formatViewport(6.2)};
  height: ${formatViewport(149, 'vh')};
  margin-bottom: ${formatViewport(18.73)};
  object-fit: cover;
  width: 100%;
`;

export const ImagePdfDocument = styled(Document)`
  border-radius: ${formatViewport(6.2)};
  height: ${formatViewport(149, 'vh')} !important;
  margin-bottom: ${formatViewport(18.73)};
  object-fit: cover;
  width: 100%;

  & > .react-pdf__Page {
    width: 100% !important;
    height: 100% !important;
  }

  & .react-pdf__Page__svg {
    display: flex !important;
    height: 100% !important;
    justify-content: center !important;
    width: 100% !important;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;

export const ImagePdfPage = styled(Page)``;

export const Title = styled.div`
  color: ${colors.white};
  font-size: ${formatViewport(18.72)};
  font-style: normal;
  font-weight: 700;
  line-height: ${formatViewport(23.4)};
  margin-bottom: ${formatViewport(7.8)};
  white-space: nowrap; /* prevent text from wrapping */
  overflow: hidden; /* hide overflowing text */
  text-overflow: ellipsis; /* add ellipsis to end of line */
  max-width: ${formatViewport(250)};
`;

export const Description = styled.div`
  color: #9ca3af;
  font-size: ${formatViewport(12.48)};
  font-style: normal;
  font-weight: 400;
  line-height: ${formatViewport(18.72)};
`;

export const CreatedByWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: ${formatViewport(10)};
  margin-top: ${formatViewport(10)};
`;

export const CreatedByInformation = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CreateByImage = styled.img`
  height: ${formatViewport(25)};
  width: ${formatViewport(25)};
`;

export const CreatedByName = styled.div`
  color: ${colors.white};
  font-size: ${formatViewport(11)};
  font-style: normal;
  font-weight: 500;
  line-height: ${formatViewport(13.65)};
`;

export const CreatedByDate = styled.div`
  color: ${colors.coolGray};
  font-size: ${formatViewport(11)};
  font-style: normal;
  font-weight: 400;
  line-height: ${formatViewport(13.65)};
`;
