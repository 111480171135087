import { PdfData } from 'shared/types';

import { axiosInstance } from '../axiosInstance';

const getPdfs = async () => {
  const { data } = await axiosInstance.get<PdfData[]>('/training-pdfs?limit=10');
  return data;
};

export default getPdfs;
