import { ReactNode, createContext, useContext, useMemo } from 'react';
import { useLocalStorage } from 'usehooks-ts';

import { Schema } from 'shared/defaultWidgetsSchema';

interface SettingsContextProps {
  darkMode: boolean;
  hideLogo: boolean;
  schema?: Schema | null;
  onSetDarkMode: (isDarkMode: boolean) => void;
  onSetHideLogo: (isLogoHide: boolean) => void;
}

const SettingsContext = createContext<SettingsContextProps>({} as SettingsContextProps);

export const useSettings = () => useContext(SettingsContext);

const SettingsProvider = ({ children }: { children: ReactNode }) => {
  const [darkMode, setDarkMode] = useLocalStorage('tv-app-dark-mode', false);
  const [hideLogo, setHideLogo] = useLocalStorage('tv-app-hide-building-logo', false);

  const onSetDarkMode = (isDarkMode: boolean) => {
    setDarkMode(isDarkMode);
  };

  const onSetHideLogo = (isLogoHide: boolean) => {
    setHideLogo(isLogoHide);
  };

  const value = useMemo(
    () => ({ darkMode, hideLogo, onSetDarkMode, onSetHideLogo }),
    [darkMode, hideLogo, onSetDarkMode, onSetHideLogo],
  );

  return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>;
};

export default SettingsProvider;
