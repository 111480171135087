import { User } from 'shared/types';

import { UploadImageFile } from '../UploadImages/types';

export enum VisibilityHours {
  INDEFINITE = 'Indefinite',
}

export interface AnnouncementsFormValues {
  date: Date;
  description: string;
  files: UploadImageFile[];
  id: number | null;
  isEditMode?: boolean;
  isEditing?: boolean;
  tags: string[];
  time: number | null;
  title: string;
  visibilityTime?: number | VisibilityHours.INDEFINITE;
  userData?: User;
}
