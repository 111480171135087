import { ListItemText, MenuItem } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';

import * as CommonPopupStyles from 'components/Popups/commonPopupStyles';
import FormDateAndTime from 'components/FormDateAndTime';
import FormDropdown from 'components/FormDropdown';
import UploadImages from 'components/UploadImages';

import { extractIdFromUrl } from 'shared/extractIdFromUrl';
import { VISIBILITY_HOURS } from 'shared/hours.constant';
import { ASPECT_RATION, ColumnOptions } from 'shared/types';

import * as S from './styles';
import { AnnouncementsFormValues } from '../../Announcements/types';
import { UploadImageFile } from '../../UploadImages/types';
import { ANNOUNCEMENT_COPY_PLACEHOLDER, ANNOUNCEMENT_HEADER_PLACEHOLDER } from './constants';

const CreateEditAnnouncements = ({
  onRemoveFile,
  columnOptions,
}: {
  onRemoveFile: React.Dispatch<React.SetStateAction<string[]>>;
  columnOptions: ColumnOptions;
}) => {
  const formik = useFormikContext<AnnouncementsFormValues>();
  const { values, handleChange, setFieldValue, errors, touched } = formik;
  const { title, description, files, visibilityTime } = values;

  const onRemoveFiles = (files: UploadImageFile[]) => {
    const getFilesIds = files.map((file) => extractIdFromUrl(file.preview)).filter((id): id is string => id !== null);
    onRemoveFile((prevFiles) => [...prevFiles, ...getFilesIds]);
  };

  return (
    <>
      <S.FormSection>
        <CommonPopupStyles.CommonTitle>Announcement header</CommonPopupStyles.CommonTitle>
        <div>
          <S.AnnouncementHeaderField
            error={Boolean(touched.title && errors.title)}
            fullWidth
            name="title"
            placeholder={ANNOUNCEMENT_HEADER_PLACEHOLDER}
            value={title}
            onChange={handleChange}
          />
          <S.HelperText error={Boolean(touched.title && errors.title)}>{errors.title}</S.HelperText>
        </div>
      </S.FormSection>
      <S.FormSection>
        <CommonPopupStyles.CommonTitle>Announcement copy</CommonPopupStyles.CommonTitle>
        <div>
          <S.AnnouncementCopyTextArea
            error={Boolean(touched.description && errors.description)}
            fullWidth
            maxRows={5}
            minRows={5}
            multiline
            name="description"
            placeholder={ANNOUNCEMENT_COPY_PLACEHOLDER}
            value={description}
            onChange={handleChange}
          />
          <S.HelperText error={touched.description && Boolean(errors.description)}>{errors.description}</S.HelperText>
        </div>
      </S.FormSection>
      <S.FilesUploadSection>
        <UploadImages
          recommendedAspectRation={ASPECT_RATION[columnOptions]}
          files={files}
          title="Add picture"
          setFiles={(newFiles) => setFieldValue('files', newFiles)}
          onRemoveFiles={onRemoveFiles}
        />
        <S.HelperText error={touched.files && Boolean(errors.files)}>{errors.files}</S.HelperText>
      </S.FilesUploadSection>
      <S.FormSection>
        <CommonPopupStyles.CommonTitle>Displaying details</CommonPopupStyles.CommonTitle>
        <S.DisplayDetails>
          <S.FullWidthWrapper>
            <S.SmallTitle>Start date</S.SmallTitle>
            <FormDateAndTime />
            <S.HelperText error={Boolean(errors.date)}>{errors.date}</S.HelperText>
          </S.FullWidthWrapper>
          <S.FullWidthWrapper>
            <S.SmallTitle>Announcement will be visible</S.SmallTitle>
            <FormDropdown
              error={Boolean(touched.visibilityTime && errors.visibilityTime)}
              value={VISIBILITY_HOURS.find((hour) => hour.hour === visibilityTime)?.time}
              onChange={(value) => setFieldValue('visibilityTime', value)}
              renderOptions={VISIBILITY_HOURS.map(({ time, hour }) => (
                <MenuItem key={time} value={hour}>
                  <ListItemText primary={time} />
                </MenuItem>
              ))}
            />
          </S.FullWidthWrapper>
        </S.DisplayDetails>
      </S.FormSection>
    </>
  );
};

export default CreateEditAnnouncements;
