import { darkModeColors, lightModeColors } from './colors';

export const lightTheme = {
  ...lightModeColors,
};
export const darkTheme = {
  ...darkModeColors,
};

export const toggleDarkModeStyles =
  (props: keyof typeof lightTheme) =>
  ({ theme }: { theme: typeof lightTheme }) =>
    theme[props];
