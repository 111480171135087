import * as Yup from 'yup';
import { startOfToday } from 'date-fns';

import { VisibilityHours } from '../components/Announcements/types';

const testVisibilityTimeSelection = (value: unknown) => {
  const isHourSelected = typeof value === 'number';
  const isIndefiniteSelected = value === VisibilityHours.INDEFINITE;
  return isHourSelected || isIndefiniteSelected;
};

export const announcementsValidationSchema = Yup.object().shape({
  date: Yup.date().min(startOfToday(), 'Date cannot be in the past').required(),
  description: Yup.string().max(100, 'Maximum 100 characters allowed').required('Required*'),
  files: Yup.array().min(1, 'Required*').max(1, 'Maximum 1 image is allowed'),
  time: Yup.number().required(),
  title: Yup.string().max(50, 'Maximum 50 characters allowed').required('Required*'),
  visibilityTime: Yup.mixed().test(
    'is-valid-time',
    'Must be a valid time or "Indefinite"',
    testVisibilityTimeSelection,
  ),
});
