import { AnnouncementsFormValues } from '../../Announcements/types';

export const updateAnnouncement = (
  announcements: AnnouncementsFormValues[],
  newAnnouncement: AnnouncementsFormValues,
) =>
  announcements.map((announcementItem) => {
    if (newAnnouncement.id === announcementItem.id) {
      return newAnnouncement;
    }
    return announcementItem;
  });
