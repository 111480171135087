import { useDropzone } from 'react-dropzone';

import { UploadImageFile } from './types';

interface UploadImagesDropzoneProps {
  files: UploadImageFile[];
  maxLength?: number;
  setFiles: (newFiles: UploadImageFile[]) => void;
}

export const useUploadImagesDropzone = ({ files, setFiles, maxLength }: UploadImagesDropzoneProps) => {
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.pdf'],
    },
    onDrop: (acceptedFiles: File[]) => {
      let slicedAcceptedFiles = acceptedFiles;
      if (maxLength) {
        slicedAcceptedFiles = acceptedFiles.slice(0, maxLength);
      }

      const newImages = slicedAcceptedFiles.map((file) =>
        Object.assign(file, {
          isPdf: file.type === 'application/pdf',
          preview: URL.createObjectURL(file),
        }),
      );

      setFiles([...files, ...newImages]);
    },
  });

  return { files, setFiles, getRootProps, getInputProps, open };
};
