import styled from 'styled-components';

import { colors } from 'shared/colors';
import { formatViewport } from 'shared/getPixelsToViewport';
import { toggleDarkModeStyles } from 'shared/theme';

import { Cross } from '../../images/svg';

const BlACK_OVERLAY_Z_INDEX = 10;

export const Overlay = styled.div`
  align-items: center;
  background: ${colors.blackOverlay};
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: ${BlACK_OVERLAY_Z_INDEX};
`;

export const ModalContent = styled.div`
  border-radius: ${formatViewport(8)};
  display: flex;
  flex-direction: column;
  gap: 0.938vw;
  padding: 0.938vw;
  background-color: ${toggleDarkModeStyles('widgetBackground')};
  border: 1px solid ${toggleDarkModeStyles('darkGray')};
  width: ${formatViewport(473)};
`;

export const CommonTitle = styled.div`
  color: ${toggleDarkModeStyles('white')};
  font-size: ${formatViewport(18)};
  line-height: ${formatViewport(23)};
`;

export const TopBarWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const TopBarTitle = styled.div`
  font-size: 0.781vw;
  font-weight: 500;
  letter-spacing: -0.02em;
  line-height: 0.977vw;
  color: ${toggleDarkModeStyles('white')};
`;

export const CrossButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;

  svg {
    width: 0.625vw;
    height: 0.625vw;
  }

  path {
    stroke: ${toggleDarkModeStyles('white')};
  }
`;

export const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const AddButton = styled.button`
  align-items: center;
  background: ${toggleDarkModeStyles('white')};
  border-radius: ${formatViewport(4)};
  border: none;
  color: ${toggleDarkModeStyles('black')};
  cursor: pointer;
  display: flex;
  font-size: ${formatViewport(18)};
  font-weight: 500;
  height: ${formatViewport(36)};
  justify-content: center;
  line-height: ${formatViewport(24)};
  padding: ${formatViewport(20)} ${formatViewport(32)};
`;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
`;

export const RemoveWrapper = styled.div`
  align-items: center;
  background: red;
  display: flex;
  height: ${formatViewport(50, 'vh')};
  justify-content: flex-end;
  margin-right: ${formatViewport(22)};
  padding: 0 10px;
  position: absolute;
  right: -${formatViewport(32)};
`;

export const Remove = styled(Cross)`
  flex-shrink: 0;
  width: ${formatViewport(18)};
  height: ${formatViewport(18, 'vh')};

  path {
    stroke: ${toggleDarkModeStyles('black')};
  }
`;
