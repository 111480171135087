export const ANNOUNCEMENT_INITIAL_VALUES = {
  date: new Date(),
  description: '',
  files: [],
  isEditMode: false,
  isEditing: false,
  tags: [],
  time: 6,
  title: '',
  visibilityTime: 24,
  id: null,
};

export const MAX_ANNOUNCEMENTS = 2;
