import urljoin from 'url-join';

import * as Styles from './WeatherHourlyDetail.styles';
import * as Types from './WeatherHourlyDetail.types';

const WeatherHourlyDetail = ({ label, icon, temp, chanceOfRain }: Types.Props) => (
  <Styles.Container>
    <Styles.Label>{label}</Styles.Label>
    <Styles.Image src={urljoin('http://openweathermap.org/img/wn/', `${icon}@4x.png`)} />
    <Styles.SmallText>{Math.round(temp)}&deg;F</Styles.SmallText>
    <Styles.SmallText>{`${Math.round(chanceOfRain * 100)}% rain`}</Styles.SmallText>
  </Styles.Container>
);

export default WeatherHourlyDetail;
