import { createContext, useContext, useMemo, useState } from 'react';

import { Column, Row } from 'shared/types';

interface OpenPopups {
  imageUploaderPopup: boolean;
  pdfsPopup: boolean;
  announcementsPopup: boolean;
}

type Popups = keyof OpenPopups;

interface ContextProps {
  activeTile: [Column, Row] | null;
  open: OpenPopups;
  onClose: (whichPopupToClose: Popups) => void;
  onOpen: (whichPopupToOpen: Popups, conditionToNotOpen?: boolean) => void;
  onSetActiveTile: (tile: [Column, Row]) => void;
}

export const Context = createContext<ContextProps>({} as ContextProps);

export const usePopupContext = () => useContext(Context);

const PopupProvider = ({ children }: { children: React.ReactNode }) => {
  const [open, setOpen] = useState<OpenPopups>({
    imageUploaderPopup: false,
    pdfsPopup: false,
    announcementsPopup: false,
  });
  const [activeTile, setActiveTile] = useState<[Column, Row] | null>(null);

  const onClose = (whichPopupToClose: Popups) => {
    setOpen((prevPopupOpen) => ({
      ...prevPopupOpen,
      [whichPopupToClose]: false,
    }));
  };

  const onOpen = (whichPopupToOpen: Popups, conditionToNotOpen?: boolean) => {
    if (conditionToNotOpen) return;
    setOpen((prevPopupOpen) => ({
      ...prevPopupOpen,
      [whichPopupToOpen]: true,
    }));
  };

  const onSetActiveTile = (tile: [Column, Row]) => {
    setActiveTile(tile);
  };

  const value = useMemo(
    () => ({ open, activeTile, onSetActiveTile, onClose, onOpen }),
    [open, activeTile, onSetActiveTile, onClose, onOpen],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
export default PopupProvider;
