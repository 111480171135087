import { Document, Page } from 'react-pdf';
import styled from 'styled-components';

import { formatViewport } from 'shared/getPixelsToViewport';
import { toggleDarkModeStyles } from 'shared/theme';

export const Container = styled.div`
  display: flex;
  border-radius: ${formatViewport(8)};
  border: 1px solid black;
  justify-content: center;
  align-items: center;
  flex: 1;
  background-color: ${toggleDarkModeStyles('widgetBackground')};
  overflow: hidden;
`;

export const PdfsList = styled.div`
  display: flex;
  height: 100%;
  position: relative;
  width: 100%;
`;

export const PdfWrapper = styled.div<{ activeIndex: number }>`
  flex-shrink: 0;
  height: 100%;
  transform: ${({ activeIndex }) => `translateX(-${activeIndex * 100}%);`}
  transition: transform 0.5s ease-in-out;
  width: 100%;
`;

export const NoPdfTitle = styled.div`
  font-size: ${formatViewport(16)};
  color: ${toggleDarkModeStyles('white')};
`;

export const PdfDocument = styled(Document)`
  height: 100% !important;

  & > .react-pdf__Page {
    width: 100% !important;
    height: 100% !important;
  }

  & .react-pdf__Page__svg {
    display: flex !important;
    height: 100% !important;
    justify-content: center !important;
    width: 100% !important;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;

export const PdfPage = styled(Page)``;
