import styled from 'styled-components';

import { formatViewport } from '../../shared/getPixelsToViewport';
import { toggleDarkModeStyles } from '../../shared/theme';

export const Container = styled.div`
  width: 100%;
  border: 1px solid black;
  border-radius: ${formatViewport(8)};
  overflow-x: scroll;
  scroll-behavior: smooth;
  transition: all 3s ease-in-out;
`;

export const Spacer = styled.div`
  background: ${toggleDarkModeStyles('white')};
  height: ${formatViewport(1, 'vh')};
  width: 100%;
`;

export const TransitWrapper = styled.div`
  display: flex;
  overflow-x: hidden;
  height: 100%;
`;

export const TransitSlides = styled.div<{ activeIndex: number }>`
  flex-shrink: 0;
  height: 100%;
  transform: ${({ activeIndex }) => `translateX(-${activeIndex * 100}%);`}
  transition: transform 0.5s ease-in-out;
  width: 100%;
`;

export const SpinnerContainer = styled.div`
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
