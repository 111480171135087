import { Schema, Tile } from '../defaultWidgetsSchema';
import { Column, ColumnOptions, Row, WidgetType } from '../types';
import { HideTilesIf } from './hideTiles';

export const updateTileInSchema = (updateTile: Tile, row: Row, column: Column, initialSchema: Schema): Tile[][] => [
  ...initialSchema.slice(0, row),
  [...initialSchema[row].slice(0, column), { ...updateTile }, ...initialSchema[row].slice(column + 1)],
  ...initialSchema.slice(row + 1),
];

export const isFullColumn = (column: ColumnOptions) => column === ColumnOptions.FullColumn;
export const isHalfColumn = (column: ColumnOptions) => column === ColumnOptions.HalfColumn;
export const isSquareColumn = (column: ColumnOptions) => column === ColumnOptions.SquareColumn;
export const isHorizontalColumn = (column: ColumnOptions) => column === ColumnOptions.HorizontalColumn;

// Check if given tile has a type === to `type[]`
export const isTileOfType = (row: Row, column: Column, schema: Schema, type: ColumnOptions[]) => {
  const tile = schema[row][column];
  return tile.columnOptions ? type.includes(tile.columnOptions) : false;
};

// Hide tile is it is a type of `type[]`
export const onTileResetHandler = (
  column: Column,
  row: Row,
  schema: Schema,
  type: ColumnOptions[],
  onTileReset: (column: Column, row: Row, type: WidgetType, columnOptions: ColumnOptions) => void,
) => {
  if (isTileOfType(row, column, schema, type)) {
    onTileReset(column, row, WidgetType.None, ColumnOptions.HalfColumn);
  }
};

export const handleHideTile = (row: Row, column: Column, schema: Schema) => {
  const position = [row, column].join('');

  switch (position) {
    case '00':
      return HideTilesIf.firstTileIsOpen(schema);
    case '01':
      return HideTilesIf.secondTileIsOpen(schema);
    case '02':
      return HideTilesIf.thirdTileIsOpen(schema);
    case '10':
      return HideTilesIf.fourthTileIsOpen(schema);
    case '11':
      return HideTilesIf.fifthTileIsOpen(schema);
    case '12':
      return HideTilesIf.sixthTileIsOpen(schema);
    default:
      return false;
  }
};

export const resetFirstTile = (
  columnsOptions: ColumnOptions,
  onTileReset: (column: Column, row: Row, type: WidgetType, columnOptions: ColumnOptions) => void,
  schema: Schema,
) => {
  if (isHorizontalColumn(columnsOptions)) {
    onTileResetHandler(1, 0, schema, [ColumnOptions.FullColumn, ColumnOptions.SquareColumn], onTileReset);
    onTileResetHandler(2, 0, schema, [ColumnOptions.SquareColumn, ColumnOptions.HorizontalColumn], onTileReset);
    onTileResetHandler(2, 1, schema, [ColumnOptions.SquareColumn], onTileReset);
    onTileResetHandler(1, 1, schema, [ColumnOptions.FullColumn, ColumnOptions.SquareColumn], onTileReset);
  }

  if (isFullColumn(columnsOptions)) {
    onTileResetHandler(0, 1, schema, [ColumnOptions.HalfColumn], onTileReset);
  }

  if (isSquareColumn(columnsOptions)) {
    onTileResetHandler(1, 0, schema, [ColumnOptions.HalfColumn], onTileReset);
    onTileResetHandler(0, 1, schema, [ColumnOptions.HalfColumn], onTileReset);
    onTileResetHandler(1, 1, schema, [ColumnOptions.HalfColumn], onTileReset);
    onTileResetHandler(2, 0, schema, [ColumnOptions.SquareColumn, ColumnOptions.HorizontalColumn], onTileReset);
    onTileResetHandler(2, 1, schema, [ColumnOptions.SquareColumn, ColumnOptions.HorizontalColumn], onTileReset);
  }
};

export const resetSecondTile = (
  columnsOptions: ColumnOptions,
  onTileReset: (column: Column, row: Row, type: WidgetType, columnOptions: ColumnOptions) => void,
  schema: Schema,
) => {
  if (isHorizontalColumn(columnsOptions)) {
    onTileResetHandler(2, 0, schema, [ColumnOptions.HalfColumn], onTileReset);
    onTileResetHandler(2, 1, schema, [ColumnOptions.FullColumn], onTileReset);
  }

  if (isFullColumn(columnsOptions)) {
    onTileResetHandler(1, 1, schema, [ColumnOptions.HalfColumn], onTileReset);
    onTileResetHandler(0, 1, schema, [ColumnOptions.HorizontalColumn], onTileReset);
    onTileResetHandler(2, 1, schema, [ColumnOptions.HorizontalColumn], onTileReset);
  }

  if (isSquareColumn(columnsOptions)) {
    onTileResetHandler(2, 0, schema, [ColumnOptions.HalfColumn, ColumnOptions.FullColumn], onTileReset);
    onTileResetHandler(
      2,
      1,
      schema,
      [ColumnOptions.HalfColumn, ColumnOptions.HorizontalColumn, ColumnOptions.FullColumn],
      onTileReset,
    );
    onTileResetHandler(1, 1, schema, [ColumnOptions.HalfColumn, ColumnOptions.HorizontalColumn], onTileReset);
    onTileResetHandler(0, 1, schema, [ColumnOptions.HorizontalColumn], onTileReset);
  }
};

export const resetThirdTile = (
  columnsOptions: ColumnOptions,
  onTileReset: (column: Column, row: Row, type: WidgetType, columnOptions: ColumnOptions) => void,
  schema: Schema,
) => {
  if (isHorizontalColumn(columnsOptions)) {
    onTileResetHandler(1, 0, schema, [ColumnOptions.HalfColumn], onTileReset);
    onTileResetHandler(0, 0, schema, [ColumnOptions.HorizontalColumn, ColumnOptions.SquareColumn], onTileReset);
    onTileResetHandler(1, 1, schema, [ColumnOptions.FullColumn], onTileReset);
    onTileResetHandler(0, 1, schema, [ColumnOptions.SquareColumn], onTileReset);
  }

  if (isFullColumn(columnsOptions)) {
    onTileResetHandler(2, 1, schema, [ColumnOptions.HalfColumn], onTileReset);
    onTileResetHandler(1, 1, schema, [ColumnOptions.HorizontalColumn], onTileReset);
  }

  if (isSquareColumn(columnsOptions)) {
    onTileResetHandler(1, 0, schema, [ColumnOptions.HalfColumn], onTileReset);
    onTileResetHandler(1, 1, schema, [ColumnOptions.HalfColumn], onTileReset);
    onTileResetHandler(2, 1, schema, [ColumnOptions.HalfColumn], onTileReset);
    onTileResetHandler(0, 0, schema, [ColumnOptions.HorizontalColumn, ColumnOptions.SquareColumn], onTileReset);
    onTileResetHandler(1, 0, schema, [ColumnOptions.HorizontalColumn, ColumnOptions.SquareColumn], onTileReset);
  }
};

export const resetFourthTile = (
  columnsOptions: ColumnOptions,
  onTileReset: (column: Column, row: Row, type: WidgetType, columnOptions: ColumnOptions) => void,
  schema: Schema,
) => {
  if (isHorizontalColumn(columnsOptions)) {
    onTileResetHandler(1, 1, schema, [ColumnOptions.HalfColumn, ColumnOptions.SquareColumn], onTileReset);
    onTileResetHandler(1, 0, schema, [ColumnOptions.FullColumn, ColumnOptions.SquareColumn], onTileReset);
    onTileResetHandler(2, 1, schema, [ColumnOptions.HorizontalColumn, ColumnOptions.SquareColumn], onTileReset);
    onTileResetHandler(2, 0, schema, [ColumnOptions.SquareColumn], onTileReset);
  }

  if (isFullColumn(columnsOptions)) {
    onTileResetHandler(0, 1, schema, [ColumnOptions.HalfColumn], onTileReset);
  }

  if (isSquareColumn(columnsOptions)) {
    onTileResetHandler(0, 0, schema, [ColumnOptions.HalfColumn], onTileReset);
    onTileResetHandler(
      1,
      0,
      schema,
      [ColumnOptions.HalfColumn, ColumnOptions.HorizontalColumn, ColumnOptions.SquareColumn],
      onTileReset,
    );
    onTileResetHandler(1, 1, schema, [ColumnOptions.HalfColumn, ColumnOptions.HorizontalColumn], onTileReset);
    onTileResetHandler(2, 0, schema, [ColumnOptions.SquareColumn, ColumnOptions.HorizontalColumn], onTileReset);
    onTileResetHandler(2, 1, schema, [ColumnOptions.SquareColumn, ColumnOptions.HorizontalColumn], onTileReset);
  }
};

export const resetFifthTile = (
  columnsOptions: ColumnOptions,
  onTileReset: (column: Column, row: Row, type: WidgetType, columnOptions: ColumnOptions) => void,
  schema: Schema,
) => {
  if (isHorizontalColumn(columnsOptions)) {
    onTileResetHandler(2, 1, schema, [ColumnOptions.HalfColumn], onTileReset);
    onTileResetHandler(2, 0, schema, [ColumnOptions.FullColumn], onTileReset);
  }

  if (isFullColumn(columnsOptions)) {
    onTileResetHandler(0, 1, schema, [ColumnOptions.HalfColumn], onTileReset);
    onTileResetHandler(2, 0, schema, [ColumnOptions.HorizontalColumn], onTileReset);
    onTileResetHandler(0, 0, schema, [ColumnOptions.HorizontalColumn], onTileReset);
  }

  if (isSquareColumn(columnsOptions)) {
    onTileResetHandler(
      1,
      0,
      schema,
      [ColumnOptions.FullColumn, ColumnOptions.HalfColumn, ColumnOptions.SquareColumn, ColumnOptions.HorizontalColumn],
      onTileReset,
    );
    onTileResetHandler(
      2,
      0,
      schema,
      [ColumnOptions.FullColumn, ColumnOptions.HalfColumn, ColumnOptions.SquareColumn, ColumnOptions.HorizontalColumn],
      onTileReset,
    );
    onTileResetHandler(
      2,
      1,
      schema,
      [ColumnOptions.FullColumn, ColumnOptions.HalfColumn, ColumnOptions.SquareColumn, ColumnOptions.HorizontalColumn],
      onTileReset,
    );
    onTileResetHandler(0, 0, schema, [ColumnOptions.HorizontalColumn], onTileReset);
  }
};

export const resetSixthTile = (
  columnsOptions: ColumnOptions,
  onTileReset: (column: Column, row: Row, type: WidgetType, columnOptions: ColumnOptions) => void,
  schema: Schema,
) => {
  if (isHorizontalColumn(columnsOptions)) {
    onTileResetHandler(1, 1, schema, [ColumnOptions.HalfColumn], onTileReset);
    onTileResetHandler(1, 0, schema, [ColumnOptions.FullColumn], onTileReset);
    onTileResetHandler(0, 1, schema, [ColumnOptions.HorizontalColumn, ColumnOptions.SquareColumn], onTileReset);
    onTileResetHandler(0, 0, schema, [ColumnOptions.SquareColumn], onTileReset);
  }

  if (isFullColumn(columnsOptions)) {
    onTileResetHandler(2, 0, schema, [ColumnOptions.HalfColumn], onTileReset);
    onTileResetHandler(1, 0, schema, [ColumnOptions.HorizontalColumn], onTileReset);
  }

  if (isSquareColumn(columnsOptions)) {
    onTileResetHandler(1, 0, schema, [ColumnOptions.HalfColumn], onTileReset);
    onTileResetHandler(2, 0, schema, [ColumnOptions.HalfColumn], onTileReset);
    onTileResetHandler(1, 1, schema, [ColumnOptions.HalfColumn], onTileReset);
    onTileResetHandler(0, 0, schema, [ColumnOptions.SquareColumn, ColumnOptions.HorizontalColumn], onTileReset);
    onTileResetHandler(0, 1, schema, [ColumnOptions.SquareColumn, ColumnOptions.HorizontalColumn], onTileReset);
  }
};
