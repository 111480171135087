import { format } from 'date-fns';
import React from 'react';

import { useSetIntervalRemoveItem } from 'hooks/useSetIntervalRemoveItem';

import * as S from './styles';
import { parseDateTimezone } from '../../../../shared/parseDateTimezone';
import { useGetNextImageIndex } from '../../../RotatingGallery/hooks';
import { UploadImageFile } from '../../../UploadImages/types';
import { MAX_ANNOUNCEMENTS } from '../../constants';
import { AnnouncementsFormValues } from '../../types';
import { Announcement } from './styles';

const getAvatarLink = (file: string) =>
  file ? `${process.env.REACT_APP_ASSETS_URL}/chitown-avatar/avatar/${file.split('/').pop()}` : '';

const AnnouncementItem = ({
  activeIndex,
  announcement,
  announcements,
  onChangeAnnouncements,
}: {
  activeIndex: number;
  announcement: AnnouncementsFormValues;
  announcements: AnnouncementsFormValues[];
  onChangeAnnouncements: (newAnnouncements: AnnouncementsFormValues[]) => void;
}) => {
  const { files, id, title, description, date, visibilityTime, userData } = announcement;
  useSetIntervalRemoveItem(announcements, id, onChangeAnnouncements, visibilityTime);

  const renderImage = (file: UploadImageFile) => {
    if (!file) return;
    if (file.isPdf) {
      return (
        <S.ImagePdfDocument file={file.preview}>
          <S.ImagePdfPage pageNumber={1} renderMode="svg" />
        </S.ImagePdfDocument>
      );
    }

    return <S.Image src={file.preview} />;
  };

  return (
    <Announcement translateValue={-activeIndex}>
      {renderImage(files[0])}
      <S.Title>{title}</S.Title>
      <S.Description>{description}</S.Description>
      <S.CreatedByWrapper>
        {userData && <S.CreateByImage src={getAvatarLink(userData?.avatar)} />}
        <S.CreatedByInformation>
          {userData && <S.CreatedByName>{`${userData?.firstName} ${userData?.lastName}`}</S.CreatedByName>}
          <S.CreatedByDate>{format(parseDateTimezone(date), 'MMM d, yyyy')}</S.CreatedByDate>
        </S.CreatedByInformation>
      </S.CreatedByWrapper>
    </Announcement>
  );
};

const HalfColumnAnnouncement = ({
  announcements,
  onChangeAnnouncements,
}: {
  announcements: AnnouncementsFormValues[];
  onChangeAnnouncements: (newAnnouncements: AnnouncementsFormValues[]) => void;
}) => {
  const { activeIndex } = useGetNextImageIndex({
    disabled: announcements.length === MAX_ANNOUNCEMENTS,
    duration: 3000,
    files: announcements ?? [],
  });

  return (
    <S.Wrapper>
      <S.MainTitle>Announcements</S.MainTitle>
      <S.AnnouncementsList>
        {announcements.map((announcement) => (
          <AnnouncementItem
            activeIndex={activeIndex}
            announcement={announcement}
            announcements={announcements}
            key={announcement.id}
            onChangeAnnouncements={onChangeAnnouncements}
          />
        ))}
      </S.AnnouncementsList>
    </S.Wrapper>
  );
};

export default HalfColumnAnnouncement;
