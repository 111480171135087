import { axiosInstance } from '../axiosInstance';

const getSchema = async (locationId: string | undefined) => {
  if (!locationId) return;
  const { data } = await axiosInstance.get<{ id: string; schema: string | null; buildingId: number }>(
    `/tv-app/locationId/${locationId}/schema`,
  );
  return data;
};

export default getSchema;
