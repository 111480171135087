const WINDOW_WIDTH = 1920;
const WINDOW_HEIGHT = 1080;

export const getPixelsToViewport = (px: number) => (100 * px) / WINDOW_WIDTH;
export const getPixelsToViewportVH = (px: number) => 100 * (px / WINDOW_HEIGHT);

export const formatViewport = (px: number, viewportType: 'vh' | 'vw' = 'vw') => {
  if (viewportType === 'vw') {
    return `${getPixelsToViewport(px)}vw`;
  }
  return `${getPixelsToViewportVH(px)}vh`;
};

export function transformVhToPx(valueVh: string) {
  const vh: number = Number.parseFloat(valueVh);
  const windowHeight: number = window.innerHeight;
  return Math.floor((vh * windowHeight) / 100);
}
