import styled from 'styled-components';

import { colors } from 'shared/colors';
import { formatViewport } from 'shared/getPixelsToViewport';

export const AnnouncementsWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
`;

export const Title = styled.div`
  color: ${colors.gunmetal};
  font-size: ${formatViewport(34)};
  font-weight: 500;
  line-height: ${formatViewport(43)};
  text-align: center;
  width: 100%;
  margin-top: ${formatViewport(12)};
`;

export const AnnouncementsList = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  width: 100%;
  gap: ${formatViewport(12)};
  padding: ${formatViewport(24)};
`;

export const Announcement = styled.div`
  border: ${formatViewport(1)} solid #000000;
  border-radius: ${formatViewport(7.7)};
  background: ${colors.white};
  width: 100%;
  min-height: ${formatViewport(358)};
  overflow: hidden;
`;

export const ImageWrapper = styled.div`
  height: ${formatViewport(181)};
  width: 100%;
  position: relative;
`;

export const Image = styled.img`
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

export const Date = styled.div`
  color: ${colors.white};
  font-size: ${formatViewport(15.5)};
  font-weight: 600;
  line-height: ${formatViewport(20)};
  position: absolute;
  top: ${formatViewport(15)};
  left: ${formatViewport(20)};
`;

export const AnnouncementTitle = styled.div`
  color: ${colors.gunmetal};
  font-size: ${formatViewport(33)};
  font-weight: 600;
  line-height: ${formatViewport(42)};
`;

export const AnnouncementDescription = styled.div`
  color: ${colors.gunmetal};
  font-size: ${formatViewport(19.4)};
  font-weight: 500;
  line-height: ${formatViewport(27)};
`;

export const AnnouncementInformation = styled.div`
  padding: ${formatViewport(24)} ${formatViewport(15)};
  display: flex;
  flex-direction: column;
  gap: ${formatViewport(20)};
`;

export const NoAnnouncementView = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
  background: ${colors.gunmetal};
  border-radius: ${formatViewport(8)};

  svg {
    width: ${formatViewport(133)};
    height: ${formatViewport(135, 'vh')};
  }
`;
