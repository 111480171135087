export const getBreakIndexArray = (elements: HTMLElement[]) =>
  elements.reduce(
    (acc, current, index) => {
      if (index < elements.length - 1 && current.offsetLeft < elements[index + 1].offsetLeft) {
        return [...acc, index + 1];
      }

      return acc;
    },
    [0],
  );
