import styled from 'styled-components';

import { colors } from 'shared/colors';
import { formatViewport } from 'shared/getPixelsToViewport';
import { toggleDarkModeStyles } from 'shared/theme';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${formatViewport(16)};
`;

export const CommonTitle = styled.div`
  font-size: ${formatViewport(18)};
  line-height: ${formatViewport(23)};
  display: flex;
  gap: ${formatViewport(10)};
  color: ${toggleDarkModeStyles('white')};
  align-items: center;
`;

export const AddPhotosButton = styled.div`
  align-items: center;
  background: ${toggleDarkModeStyles('white')};
  border-radius: 9999px;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
  color: white;
  cursor: pointer;
  display: flex;
  height: ${formatViewport(24)};
  width: ${formatViewport(24)};
  justify-content: center;

  svg {
    height: ${formatViewport(15)};
    width: ${formatViewport(15)};

    path {
      stroke: ${toggleDarkModeStyles('black')};
    }
  }
`;

export const ContentDialogWindow = styled.div`
  align-items: center;
  background: ${colors.lightLavender};
  border-radius: ${formatViewport(8)};
  border: ${formatViewport(2)} dashed ${colors.silver};
  display: flex;
  height: ${formatViewport(150)};
  flex-direction: row;
  justify-content: center;
`;

export const Placeholder = styled.div`
  font-size: ${formatViewport(14)};
  font-weight: 500;
  line-height: ${formatViewport(18)};
  position: absolute;
  text-align: center;
  z-index: 1;
`;

export const Photos = styled.div`
  display: flex;
  gap: ${formatViewport(10)};
  overflow-x: auto;
  width: 100%;
  height: 100%;
  z-index: 2;
  padding: ${formatViewport(10)};
`;
