import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import WidgetsProvider from 'providers/WidgetsProvider';

import MainPage from './components/MainPage';
import PopupProvider from './providers/PopupProvider';
import SettingsProvider from './providers/SettingsProvider';
import ThemeProvider from './providers/ThemeProvider';

const queryClient = new QueryClient();

const App = () => (
  <QueryClientProvider client={queryClient}>
    <SettingsProvider>
      <ThemeProvider>
        <WidgetsProvider>
          <PopupProvider>
            <MainPage />
          </PopupProvider>
        </WidgetsProvider>
      </ThemeProvider>
    </SettingsProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);

export default App;
