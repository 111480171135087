import { chunk, uniqBy } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useQuery } from 'react-query';

import { getTransit } from '../../api/getTransit';
import useWidgetsContext from '../../hooks/useWidgetsContext';
import { colors } from '../../shared/colors';
import { transformVhToPx } from '../../shared/getPixelsToViewport';
import { useGetNextImageIndex } from '../RotatingGallery/hooks';
import { Icons } from './Icons/Icons';
import { HEIGHT_OF_STATION } from './constants';
import { TransitDeparture, TransitDepartureResponse } from './type';
import { createTransitSlides, isBusArrived, prepareData } from './utils';

import * as StylesSection from './TransportationSection.styles';
import * as StylesMain from './TransportationWidget.styles';

type TransportationItemProps = { departures: [string, TransitDeparture]; isLast: boolean };

const TransportationSection = ({ departures, isLast }: TransportationItemProps) => {
  const [stationName, station] = departures;

  return (
    <>
      <StylesSection.Container>
        <StylesSection.Header>
          <StylesSection.HeaderTitle>{stationName}</StylesSection.HeaderTitle>
          <StylesSection.TransitIconsGroup>
            {uniqBy(station.transports, 'mode').map(({ mode }, i) => (
              <StylesSection.TransitIcon key={i}>
                <Icons iconType={mode} />
              </StylesSection.TransitIcon>
            ))}
          </StylesSection.TransitIconsGroup>
        </StylesSection.Header>
        <StylesSection.TransportWrapper>
          <StylesSection.TransportInfoWrapper>
            <StylesSection.HeaderLabel style={{ visibility: 'hidden' }}>-</StylesSection.HeaderLabel>
            {station.transports.map((station) => (
              <StylesSection.Row key={station.headsign}>
                <StylesSection.Col1>
                  <StylesSection.StationName style={{ background: '#8A8787' }}>
                    {station.name}
                  </StylesSection.StationName>
                  <StylesSection.TransitText>{station.headsign}</StylesSection.TransitText>
                </StylesSection.Col1>
              </StylesSection.Row>
            ))}
          </StylesSection.TransportInfoWrapper>
          <StylesSection.TransportInfoWrapper>
            <StylesSection.HeaderLabel>Arrives (min)</StylesSection.HeaderLabel>
            {station.transports.map((station) => (
              <StylesSection.Row key={station.headsign}>
                <StylesSection.Col1>
                  <StylesSection.RowArrivesText>{isBusArrived(station.time)}</StylesSection.RowArrivesText>
                </StylesSection.Col1>
              </StylesSection.Row>
            ))}
          </StylesSection.TransportInfoWrapper>
        </StylesSection.TransportWrapper>
      </StylesSection.Container>
      {!isLast ? <StylesMain.Spacer /> : null}
    </>
  );
};

const TransportationWidget = () => {
  const [position, setPosition] = useState<{ lat: number; long: number } | null>(null);
  const transitHeight = useRef<HTMLDivElement | null>(null);
  const { buildingId } = useWidgetsContext();

  const {
    isLoading,
    data: stops,
    isIdle,
    refetch,
    isError,
  } = useQuery<unknown, unknown, { boards: TransitDepartureResponse[] }>(
    ['transit', position],
    () => getTransit(buildingId),
    {
      refetchOnWindowFocus: false,
      retryOnMount: false,
      cacheTime: 1_800_000,
      refetchInterval: 100_000,
    },
  );

  useEffect(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        setPosition({ lat: latitude, long: longitude });
        refetch();
      });
    }
  }, []);

  const transit: { [key: string]: TransitDeparture } | null = useMemo(() => {
    if (!isLoading && stops) {
      return prepareData(stops?.boards);
    }
    return null;
  }, [isLoading, stops]);

  const transitSlides = useMemo(() => createTransitSlides(Object.entries(transit || {}), []), [transit]);

  const chunkTransitSlides = () => {
    const height = transitHeight.current?.offsetHeight;
    const stationHeight = transformVhToPx(HEIGHT_OF_STATION);
    if (!height) return [];
    const chunkCount = Math.floor(height / stationHeight);

    return chunk(transitSlides.flat(), chunkCount);
  };

  const transitChunks = chunkTransitSlides();

  const { activeIndex } = useGetNextImageIndex({
    disabled: false,
    duration: 5000,
    files: transitChunks,
  });

  const renderTransit = () =>
    transitChunks.map((transit) => (
      <StylesMain.TransitSlides activeIndex={activeIndex}>
        {transit.map((route, i, arr) => (
          <TransportationSection isLast={arr.length - 1 === i} departures={route} key={route[0]} />
        ))}
      </StylesMain.TransitSlides>
    ));

  return (
    <>
      <StylesMain.Container>
        {isLoading && !transit && (
          <StylesMain.SpinnerContainer>
            <TailSpin color={colors.main} />
          </StylesMain.SpinnerContainer>
        )}
        {isIdle && !position && <div>Please enable geolocation</div>}
        {isError && !stops && <div>Error occurred while fetching data</div>}
        <StylesMain.TransitWrapper ref={transitHeight}>{renderTransit()}</StylesMain.TransitWrapper>
      </StylesMain.Container>
    </>
  );
};

export default TransportationWidget;
