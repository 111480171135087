import { Select } from '@mui/material';
import styled from 'styled-components';

import { ReactComponent as Arrow } from 'images/svg/arrow.svg';

import { formatViewport } from 'shared/getPixelsToViewport';
import { toggleDarkModeStyles } from 'shared/theme';

export const Dropdown = styled(Select)`
  font-size: ${formatViewport(16)};
  border: ${formatViewport(1)} solid ${toggleDarkModeStyles('white')};

  & {
    height: ${formatViewport(36)};
    padding: 0;
  }

  && .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled {
    -webkit-text-fill-color: ${toggleDarkModeStyles('silver')};
    cursor: not-allowed;
  }

  & > .MuiOutlinedInput-input {
    height: ${formatViewport(36)} !important;

    &.MuiSelect-select {
      align-items: center;
      display: flex;
      overflow: initial;
      padding: 0 0 0 ${formatViewport(16)};
    }
  }

  &.MuiInputBase-root {
    fieldset {
      border-width: 0;
    }
  }
`;

export const ArrowIconWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
`;

export const ArrowIcon = styled(Arrow)<{ isOpen: boolean }>`
  flex-shrink: 0;
  height: ${formatViewport(7)};
  margin-right: ${formatViewport(14)};
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  width: ${formatViewport(13.33)};

  path {
    stroke: ${toggleDarkModeStyles('white')};
  }
`;

export const RenderedValue = styled.div`
  color: ${toggleDarkModeStyles('settingsPopupInput')};
  font-size: ${formatViewport(18)};
  overflow: initial;
`;
