import { User } from 'shared/types';

import { axiosInstance } from '../axiosInstance';

const getUser = async () => {
  const { data } = await axiosInstance.get(`/tv-app/user`);
  return data as User;
};

export default getUser;
