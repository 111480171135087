export enum DropdownIcons {
  FullColumn = 'Full column',
  HalfColumn = 'Half column',
  HorizontalColumn = 'Horizontal column',
  SquareColumn = 'Square column',
  Delete = 'Delete Icon',
}

export interface DropdownButtonProps {
  icon?: DropdownIcons;
  text?: string;
  onSelect: () => void;
}
