export const colors = {
  black: '#000000',
  blackOverlay: 'rgba(0, 0, 0, 0.4)',
  coolGray: '#9CA3AF',
  crimson: '#d01032',
  darkBlue: 'rgba(19, 21, 55, 1)',
  darkGray: '#595d62',
  greenishGrey: '#1B201C',
  gunmetal: '#363636',
  lavenderGray: '#afb4ba',
  lightLavender: '#e7e7eb',
  main: '#1A2A4C',
  silver: '#b9b9bc',
  snowyWhite: '#f7f8fa',
  white: '#ffffff',
  whiteSmoke: '#f5f5f5',
};

export const darkModeColors = {
  black: '#000000',
  blackOverlay: 'rgba(0, 0, 0, 0.4)',
  coolGray: '#9CA3AF',
  crimson: '#d01032',
  darkBlue: 'rgba(19, 21, 55, 1)',
  darkGray: '#595d62',
  greenishGrey: '#1B201C',
  gunmetal: 'white',
  lavenderGray: '#afb4ba',
  lightLavender: '#e7e7eb',
  main: '#1A2A4C',
  silver: '#b9b9bc',
  snowyWhite: '#f7f8fa',
  white: '#ffffff',
  whiteSmoke: '#f5f5f5',
  whiteBoxShadow: 'rgba(255,255,255,0.5)',
  dropDownText: 'lightgrey',
  dropDownSubText: '#000000',
  widgetBackground: 'rgba(19, 21, 55, 1)',
  settingsPopupInput: '#b9b9bc',
  tooltipOrangeIcon: 'white',
  tooltipOrangeText: 'white',
  tooltipOrangeBackground: '#EE8E00',
};

export const lightModeColors = {
  black: '#ffffff',
  blackOverlay: 'rgba(255, 255, 255, 0.4)',
  coolGray: '#575e6b',
  crimson: '#e5324d',
  darkBlue: 'rgba(68, 74, 111, 1)',
  darkGray: '#8a8e93',
  greenishGrey: '#8e948d',
  gunmetal: '#363636',
  lavenderGray: '#bdc2c8',
  lightLavender: '#f5f5f9',
  main: '#3c4c76',
  silver: '#5d5d60',
  snowyWhite: '#d7d8da',
  white: '#000000',
  whiteSmoke: '#9e9e9e',
  whiteBoxShadow: 'black',
  dropDownText: '#595d62',
  dropDownSubText: '#ffffff',
  widgetBackground: '#ffffff',
  settingsPopupInput: '#8a8e93',
  tooltipOrangeIcon: '#EE8E00',
  tooltipOrangeText: '#EE8E00',
  tooltipOrangeBackground: '#fdf7ee',
};
