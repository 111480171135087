import React, { useState } from 'react';
import { TailSpin } from 'react-loader-spinner';

import { Cross } from 'images/svg/';

import { ASPECT_RATION } from 'shared/types';

import { extractIdFromUrl } from '../../../shared/extractIdFromUrl';
import ImageRotationTime from '../../ImageRotationTime';
import UploadImages from '../../UploadImages';
import { UploadImageFile } from '../../UploadImages/types';
import Popup from '../Popup.component';
import { UPLOAD_IMAGES_OPTIONS } from './constant';
import { UploadImagesPopupProps } from './types';

import * as S from './UploadImagesPopup.styles';

const UploadImagesPopup = ({
  columnOptions,
  defaultDurationValue,
  isLoading,
  files,
  setRotationDuration,
  onClose,
  onSave,
}: UploadImagesPopupProps) => {
  const [uploadedFiles, setUploadedFiles] = useState<UploadImageFile[]>(files);
  const [removedFilesIds, setRemovedFilesIds] = useState<string[]>([]);

  const onRemoveFiles = (files: UploadImageFile[]) => {
    const getFilesIds = files.map((file) => extractIdFromUrl(file.preview)).filter((id): id is string => id !== null);
    setRemovedFilesIds((prevFiles) => [...prevFiles, ...getFilesIds]);
  };

  const onSubmit = async () => {
    onSave(uploadedFiles, removedFilesIds);
    setRemovedFilesIds([]);
  };

  return (
    <Popup portalName="upload-images-portal">
      <S.Overlay>
        <S.ModalContent>
          <S.TopBarWrapper>
            <S.TopBarTitle>Add Image Rotating Gallery</S.TopBarTitle>
            <S.CrossButton onClick={onClose}>
              <Cross />
            </S.CrossButton>
          </S.TopBarWrapper>
          <UploadImages
            recommendedAspectRation={ASPECT_RATION[columnOptions]}
            files={uploadedFiles}
            setFiles={setUploadedFiles}
            onRemoveFiles={onRemoveFiles}
          />
          <ImageRotationTime
            defaultValue={String(defaultDurationValue)}
            dropdownTitle="Change image every..."
            options={UPLOAD_IMAGES_OPTIONS}
            title="Displaying details"
            onSelect={setRotationDuration}
          />
          <S.FooterWrapper>
            <S.AddButton onClick={onSubmit} disabled={isLoading}>
              {isLoading ? (
                <S.SpinnerWrapper>
                  <TailSpin />
                </S.SpinnerWrapper>
              ) : (
                'Add'
              )}
            </S.AddButton>
          </S.FooterWrapper>
        </S.ModalContent>
      </S.Overlay>
    </Popup>
  );
};

export default UploadImagesPopup;
