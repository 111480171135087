import { useEffect, useState } from 'react';

interface GetNextImageProps {
  disabled: boolean;
  duration: number;
  files: unknown[];
}

// Note: every `duration` we will get new index of the image to show
// If we see an image, and we remove it, I find the `lastValidIndex` of an image to show
export const useGetNextImageIndex = ({ disabled, files, duration }: GetNextImageProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [lastValidIndex, setLastValidIndex] = useState(0);

  useEffect(() => {
    if (files.length === 0 || disabled) return;
    const interval = setInterval(() => {
      if (activeIndex === files.length - 1) {
        setActiveIndex(0);
        return;
      }

      setActiveIndex((activeIndex) => {
        const nextIndex = (activeIndex + 1) % files.length;
        if (files[nextIndex]) {
          setLastValidIndex(nextIndex);
          return nextIndex;
        }
        return lastValidIndex;
      });
    }, duration);

    return () => clearInterval(interval);
  }, [activeIndex, files, disabled, duration, lastValidIndex]);

  useEffect(() => {
    // check if current active index is still valid
    if (!files[activeIndex]) {
      setActiveIndex(lastValidIndex);
    }
    setLastValidIndex(0);
  }, [files, activeIndex, lastValidIndex]);

  return { activeIndex };
};
