import styled from 'styled-components';

import { toggleDarkModeStyles } from 'shared/theme';

import { formatViewport } from '../../shared/getPixelsToViewport';

export const Container = styled.div`
  margin: 20px;
  border-radius: ${formatViewport(8)};
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${toggleDarkModeStyles('widgetBackground')};
  flex-wrap: wrap;
  overflow: hidden;
  width: 100%;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
