import styled from 'styled-components';

import { formatViewport } from 'shared/getPixelsToViewport';
import { toggleDarkModeStyles } from 'shared/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${formatViewport(16)};
`;

export const Title = styled.div`
  color: ${toggleDarkModeStyles('white')};
  font-size: ${formatViewport(18)};
  line-height: ${formatViewport(23)};
`;

export const DropdownWrapper = styled.div``;

export const DropdownTitle = styled.div`
  color: ${toggleDarkModeStyles('white')};
  font-size: ${formatViewport(14)};
  font-weight: 500;
  line-height: ${formatViewport(18)};
  margin-bottom: ${formatViewport(4)};
`;
