import React from 'react';

import { Cross } from 'images/svg';

import * as S from './styles';
import { PhotoPreviewProps } from './types';

const PhotoPreview = ({ file, onRemove }: PhotoPreviewProps) => {
  const onRemovePhoto = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onRemove(file);
  };

  const renderFile = () => {
    if (file.isPdf) {
      return (
        <S.PdfDocument className="pdf-container">
          <object data={`${file.preview}#view=FitH`} type="application/pdf">
            <embed src={`${file.preview}#view=FitH`} type="application/pdf" />
          </object>
        </S.PdfDocument>
      );
    }

    return <S.Photo src={file.preview} />;
  };

  return (
    <S.PhotoWrapper key={file.preview}>
      {renderFile()}
      <S.RemovePhotoIcon onClick={onRemovePhoto}>
        <Cross />
      </S.RemovePhotoIcon>
    </S.PhotoWrapper>
  );
};

export default PhotoPreview;
