import { getTime } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';

export const getTimezonedHoursAndMinutes = (dateMs: number, timeZone: string, formatStr: string = 'h:mm') =>
  format(utcToZonedTime(new Date(dateMs), timeZone), formatStr);

export const getCurrentTime = (date: Date, timeZone: string) => getTimezonedHoursAndMinutes(getTime(date), timeZone);

export const getAMPM = (date: Date, timeZone: string) => format(utcToZonedTime(date, timeZone), 'aa');

export const getFullDay = (date: Date, timeZone: string) => {
  const zonedTime = utcToZonedTime(date, timeZone);
  return `${format(zonedTime, 'eeee')}, ${format(zonedTime, 'LLLL')} ${format(zonedTime, 'd')}`;
};
