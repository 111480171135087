import styled from 'styled-components';

import { colors } from 'shared/colors';
import { formatViewport } from 'shared/getPixelsToViewport';
import { toggleDarkModeStyles } from 'shared/theme';

const BlACK_OVERLAY_Z_INDEX = 10;

export const Overlay = styled.div`
  align-items: center;
  background: ${colors.blackOverlay};
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: ${BlACK_OVERLAY_Z_INDEX};
`;

export const ModalContent = styled.div`
  background: ${toggleDarkModeStyles('widgetBackground')};
  border: 1px solid ${toggleDarkModeStyles('darkGray')};
  border-radius: ${formatViewport(8)};
  display: flex;
  flex-direction: column;
  gap: ${formatViewport(24)};
  padding: ${formatViewport(24)};
  width: ${formatViewport(473)};
`;

export const CommonTitle = styled.div`
  font-size: ${formatViewport(18)};
  line-height: ${formatViewport(23)};
`;

export const TopBarWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const TopBarTitle = styled.div`
  font-size: ${formatViewport(20)};
  font-weight: 500;
  letter-spacing: -0.02em;
  line-height: ${formatViewport(25)};
  color: ${toggleDarkModeStyles('white')};
`;

export const CrossButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;

  svg {
    width: ${formatViewport(16)};
    height: ${formatViewport(16)};
  }

  path {
    stroke: ${toggleDarkModeStyles('white')};
  }
`;

export const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const AddButton = styled.button`
  align-items: center;
  background: ${toggleDarkModeStyles('white')};
  border-radius: ${formatViewport(4)};
  border: none;
  color: ${toggleDarkModeStyles('black')};
  cursor: pointer;
  display: flex;
  font-size: ${formatViewport(18)};
  font-weight: 500;
  height: ${formatViewport(36)};
  justify-content: center;
  line-height: ${formatViewport(24)};
  padding: ${formatViewport(20)} ${formatViewport(32)};
`;

export const SpinnerWrapper = styled.div`
  svg {
    width: ${formatViewport(18)};
    height: ${formatViewport(18)};
    path {
      stroke: ${toggleDarkModeStyles('black')};
    }
  }
`;
