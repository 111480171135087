import { WeatherData } from 'shared/types';

import { axiosInstance } from '../axiosInstance';

const getWeather = async (buildingId: number | null) => {
  if (buildingId === null) return;
  const { data } = await axiosInstance.get<WeatherData>(`/weather/${buildingId}`);
  return data;
};

export default getWeather;
