import styled from 'styled-components';

import { colors } from 'shared/colors';
import { formatViewport } from 'shared/getPixelsToViewport';
import { toggleDarkModeStyles } from 'shared/theme';

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 2px;
  padding: 6px;
  border-radius: ${formatViewport(8)};

  :hover {
    cursor: pointer;
    background-color: ${colors.lavenderGray};
    color: white;
  }
`;

const BaseText = styled.div`
  font-size: ${formatViewport(18)};
  margin-left: ${formatViewport(12)};
`;

export const ColumnOptionText = styled(BaseText)`
  font-size: ${formatViewport(20)};
  color: ${toggleDarkModeStyles('white')};
`;

export const IconWrapper = styled.div`
  svg {
    width: ${formatViewport(13)};
    height: ${formatViewport(15)};
  }
  path {
    fill: ${toggleDarkModeStyles('white')};
  }
`;
