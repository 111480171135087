import { ReactNode } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { darkTheme, lightTheme } from 'shared/theme';

import { useSettings } from '../SettingsProvider/SettingsProvider';

const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const { darkMode } = useSettings();
  const theme = darkMode ? darkTheme : lightTheme;

  return <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>;
};

export default ThemeProvider;
