import { Checkbox, ListItemText, MenuItem } from '@mui/material';
import styled from 'styled-components';

import { formatViewport } from 'shared/getPixelsToViewport';
import { toggleDarkModeStyles } from 'shared/theme';

import { Overlay } from '../commonPopupStyles';

export const OverlayWrapper = styled(Overlay)`
  z-index: 20;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${formatViewport(6)};
`;

export const SwitchControl = styled(Checkbox)`
  & > .MuiSvgIcon-root {
    cursor: pointer;
    height: ${formatViewport(35)};
    width: ${formatViewport(35)};
  }

  && {
    color: ${toggleDarkModeStyles('silver')};
  }
`;

export const Label = styled.div`
  font-size: ${formatViewport(16)};
  color: ${toggleDarkModeStyles('white')};
`;

export const TemplateTitle = styled.div`
  font-size: ${formatViewport(16)};
  color: ${toggleDarkModeStyles('white')};
`;

export const StyledMenuItem = styled(MenuItem)``;

export const ListItem = styled(ListItemText)`
  & .MuiTypography-root {
    font-size: ${formatViewport(16)};
    padding: ${formatViewport(2)} ${formatViewport(16)};
  }
`;

export const UploadLogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${formatViewport(8)};
  margin-bottom: ${formatViewport(12)};
`;

export const UploadFile = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UploadFileText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${formatViewport(300)};
  text-align: center;
`;

export const SpinnerWrapper = styled.div`
  svg {
    width: ${formatViewport(18)};
    height: ${formatViewport(18)};
    path {
      stroke: ${toggleDarkModeStyles('black')};
    }
  }
`;
