import { useContext } from 'react';

import { WidgetsContext } from 'providers/WidgetsProvider/WidgetsProvider';

import { IWidgetsContext } from '../providers/WidgetsProvider/WidgetsProvider.types';

const useWidgetsContext = () => {
  const context = useContext(WidgetsContext) as IWidgetsContext;

  if (context === undefined) {
    throw new Error('useWidgetsContext must be used within a WidgetsProvider');
  }

  return context;
};

export default useWidgetsContext;
