import styled from 'styled-components';

import { formatViewport } from 'shared/getPixelsToViewport';
import { toggleDarkModeStyles } from 'shared/theme';

export const Container = styled.div`
  display: flex;
  border-radius: ${formatViewport(8)};
  width: 100%;
  border: 1px solid ${toggleDarkModeStyles('darkGray')};
  margin-bottom: ${formatViewport(20)};
  margin-right: ${formatViewport(24)};
  background-color: ${toggleDarkModeStyles('black')};
`;

export const BackgroundImageContainer = styled.div`
  position: relative;
  height: fit-content;
`;

export const BackgroundImage = styled.img`
  border-top-left-radius: ${formatViewport(8)};
  border-bottom-left-radius: ${formatViewport(8)};
  width: ${formatViewport(230)};
`;

export const BadgeContainer = styled.div`
  position: absolute;
  top: ${formatViewport(18)};
  left: ${formatViewport(12)};
  display: flex;
  align-items: center;
  min-width: ${formatViewport(108)};
  justify-content: space-between;
  padding: ${formatViewport(4)} ${formatViewport(8)} ${formatViewport(4)} ${formatViewport(4)};
  background-color: white;
  border: 1px solid black;
  border-radius: ${formatViewport(8)};
  height: ${formatViewport(56)};
`;

export const BadgeImage = styled.img`
  object-fit: cover;
  height: ${formatViewport(48)};
`;

export const BadgeLabel = styled.div`
  font-size: ${formatViewport(20)};
  font-weight: 500;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${formatViewport(20)};
`;

export const Header = styled.div`
  font-size: ${formatViewport(30)};
  font-weight: 600;
  margin-bottom: ${formatViewport(8)};
  color: ${toggleDarkModeStyles('white')};
`;

export const MainText = styled.div`
  font-size: ${formatViewport(12)};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  color: ${toggleDarkModeStyles('white')};
`;
