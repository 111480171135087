import styled from 'styled-components';

import { ReactComponent as Settings } from 'images/svg/settings.svg';

import { formatViewport } from 'shared/getPixelsToViewport';
import { toggleDarkModeStyles } from 'shared/theme';

export const Wrapper = styled.div`
  height: 100%;
`;

export const VerticalLine = styled.div<{ open: boolean }>`
  align-items: center;
  background: ${({ open }) => (open ? toggleDarkModeStyles('widgetBackground') : 'none')};
  box-shadow: ${({ open }) => (open ? '0 0 10px rgba(0, 0, 0, 0.5)' : null)};
  border-left: 1px solid ${({ open }) => (open ? toggleDarkModeStyles('darkGray') : 'none')};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.3s ease;
  width: ${({ open }) => (open ? formatViewport(70) : formatViewport(10))};
  z-index: 100;
`;

export const SettingsIcon = styled(Settings)`
  cursor: pointer;
  height: ${formatViewport(25)};
  width: ${formatViewport(25)};

  path {
    stroke: ${toggleDarkModeStyles('silver')};
  }
`;
