import React from 'react';

export const Bus = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490.5 490.5">
    <path d="M173.1 343.3h-17.7c-11.5 0-20.9 9.4-20.9 20.9s9.4 20.9 20.9 20.9h17.7c11.5 0 20.9-9.4 20.9-20.9-.1-11.5-9.5-20.9-20.9-20.9zM334.7 343.3H317c-11.5 0-20.9 9.4-20.9 20.9s9.4 20.9 20.9 20.9h17.7c11.5 0 20.9-9.4 20.9-20.9-.1-11.5-9.5-20.9-20.9-20.9z" />
    <path d="M469.1 50.3h-34.4V44c0-24-19.8-43.8-43.8-43.8H99C75 .2 55.2 20 55.2 44v7.3H20.9C9.4 51.4 0 60.8 0 72.2v91.7c0 11.5 9.4 20.9 20.9 20.9 10.4 0 19.8-9.4 19.8-19.8V93.1h14.6V410c0 15.6 8.4 29.4 20.9 37.2v22.2c0 11.5 9.4 20.9 20.9 20.9s20.9-9.4 20.9-20.9v-15.6h254.4v15.6c0 11.5 9.4 20.9 20.9 20.9s19.8-9.4 20.9-20.9v-22.2c12.5-7.8 20.9-21.6 20.9-37.2V92h13.6v71.9c0 11.5 9.4 20.9 20.9 20.9s20.9-9.4 20.9-20.9V71.2c-.5-11.5-9.9-20.9-21.4-20.9zM99 413.2c-2.1 0-3.1-1-3.1-3.1v-87.6h298.2v87.6c0 2.1-1 3.1-3.1 3.1H99zM99 41h292c2.1 0 3.1 1 3.1 3.1v217.6l-87.6-65.9V164c0-11.5-9.4-20.9-20.9-20.9s-20.9 9.4-20.9 20.9v84.4c0 11.5 9.4 20.9 20.9 20.9s19.8-9.4 20.9-20.9v-2.3l46 34.6h-205l46.4-35.2v2.9c0 11.5 9.4 20.9 20.9 20.9 10.4 0 19.8-9.4 20.9-20.9V164c0-11.5-9.4-20.9-20.9-20.9s-20.9 9.4-20.9 20.9v31l-98 73.7V44.1C95.9 42 97 41 99 41z" />
  </svg>
);

export const Train = () => (
  <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 0.666656C5.10666 0.666656 0.333328 1.33332 0.333328 5.99999V18.6667C0.333328 21.24 2.42666 23.3333 4.99999 23.3333L3.46666 24.8667C3.05333 25.28 3.34666 26 3.94666 26H18.0667C18.6667 26 18.96 25.28 18.5333 24.8667L17 23.3333C19.5733 23.3333 21.6667 21.24 21.6667 18.6667V5.99999C21.6667 1.33332 16.8933 0.666656 11 0.666656ZM4.99999 20.6667C3.89333 20.6667 2.99999 19.7733 2.99999 18.6667C2.99999 17.56 3.89333 16.6667 4.99999 16.6667C6.10666 16.6667 6.99999 17.56 6.99999 18.6667C6.99999 19.7733 6.10666 20.6667 4.99999 20.6667ZM9.66666 12.6667H2.99999V5.99999H9.66666V12.6667ZM17 20.6667C15.8933 20.6667 15 19.7733 15 18.6667C15 17.56 15.8933 16.6667 17 16.6667C18.1067 16.6667 19 17.56 19 18.6667C19 19.7733 18.1067 20.6667 17 20.6667ZM19 12.6667H12.3333V5.99999H19V12.6667Z"
      fill="black"
    />
  </svg>
);

export const Subway = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 297 297">
    <path d="M109.597 211.296c8.821 0 15.999-7.177 15.999-15.998s-7.178-15.999-15.999-15.999-15.998 7.178-15.998 15.999c0 8.821 7.176 15.998 15.998 15.998zm0-19.898c2.15 0 3.9 1.75 3.9 3.9s-1.75 3.899-3.9 3.899-3.9-1.749-3.9-3.899a3.904 3.904 0 0 1 3.9-3.9zM186.895 211.296c8.821 0 15.998-7.177 15.998-15.998s-7.177-15.999-15.998-15.999-15.999 7.178-15.999 15.999c0 8.821 7.178 15.998 15.999 15.998zm0-19.898a3.905 3.905 0 0 1 3.899 3.9c0 2.15-1.75 3.899-3.899 3.899-2.15 0-3.9-1.749-3.9-3.899s1.75-3.9 3.9-3.9z" />
    <path d="m294.404 275.71-40.174-47.674c10.043-11.89 14.943-27.071 13.842-43.086l-8.409-122.084C257.275 28.201 227.066 0 192.319 0h-88.146C69.428 0 39.217 28.201 36.829 62.865l-8.408 122.086c-1.112 16.147 3.888 31.446 14.102 43.379L2.597 275.709c-3.541 4.202-3.005 10.479 1.196 14.019a9.907 9.907 0 0 0 6.407 2.342 9.927 9.927 0 0 0 7.612-3.538L57.8 241.078a58.984 58.984 0 0 0 10.803 4.892l-18.848 36.516c-2.52 4.883-.605 10.885 4.277 13.404a9.949 9.949 0 0 0 13.405-4.278l5.634-10.914h149.843l5.633 10.914a9.949 9.949 0 1 0 17.682-9.126l-18.771-36.369a58.855 58.855 0 0 0 11.573-5.238l40.156 47.653a9.928 9.928 0 0 0 7.613 3.539 9.91 9.91 0 0 0 6.406-2.341c4.202-3.541 4.738-9.819 1.198-14.02zM192.319 19.898c9.731 0 18.951 3.145 26.645 8.457l-1.061 4.245H78.59l-1.062-4.245c7.693-5.312 16.914-8.457 26.645-8.457h88.146zm20.61 32.601-23.551 94.2h-82.264l-23.55-94.2h129.365zM48.272 186.317 56.68 64.233c.432-6.269 2.161-12.232 4.896-17.644l28.117 112.473a9.948 9.948 0 0 0 9.652 7.536h97.799a9.948 9.948 0 0 0 9.652-7.536l28.12-112.472c2.735 5.411 4.464 11.374 4.894 17.644l8.41 122.084c.789 11.478-2.947 22.268-10.523 30.382-7.575 8.115-18.082 12.584-29.586 12.584H88.382c-11.504 0-22.011-4.469-29.587-12.584-7.575-8.115-11.313-18.905-10.523-30.383zM83.341 260.8l5.997-11.617h117.309l5.996 11.617H83.341z" />
  </svg>
);

export const Plane = () => (
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.496 17.414c-.394-1.096-1.805-4.775-2.39-6.297-1.103.737-2.334 1.435-3.512 1.928-.366 1.28-1.094 3.709-1.446 4.033-.604.557-.832.485-.925-.279-.093-.764-.485-3.236-.485-3.236s-2.162-1.219-2.84-1.568-.667-.591.057-.974c.422-.223 2.927-.085 4.242.005.861-.951 1.931-1.882 2.993-2.679-1.215-1.076-4.15-3.675-5.034-4.424-.776-.658.079-.797.079-.797.39-.07 1.222-.132 1.628-.009 2.524.763 6.442 2.068 7.363 2.376l1.162-.821c4.702-3.33 5.887-2.593 6.111-2.27s.503 1.701-4.199 5.032l-1.16.823c-.029.98-.157 5.151-.311 7.811-.025.428-.367 1.198-.565 1.544-.001 0-.423.765-.768-.198z" />
  </svg>
);

export const busRapid = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 489.9 489.9">
    <path d="M173.244 343h-17.7c-11.5 0-20.8 9.4-20.8 20.8 0 11.5 9.4 20.8 20.8 20.8h17.7c11.5 0 20.8-9.4 20.8-20.8s-9.4-20.8-20.8-20.8zM334.844 343h-17.7c-11.5 0-20.8 9.4-20.8 20.8 0 11.5 9.4 20.8 20.8 20.8h17.7c11.5 0 20.8-9.4 20.8-20.8s-9.4-20.8-20.8-20.8z" />
    <path d="M469.244 50.1h-34.4v-6.3c0-24-19.8-43.8-43.8-43.8h-291.8c-24 0-43.8 19.8-43.8 43.8v7.3h-34.4c-11.5 0-20.9 9.4-20.9 20.9v91.7c0 11.5 9.4 20.8 20.8 20.8 10.4 0 19.8-9.4 19.8-19.8V92.8h14.6v316.9c0 15.6 8.4 29.4 20.8 37.2v22.2c0 11.5 9.4 20.8 20.8 20.8 11.5 0 20.8-9.4 20.8-20.8v-15.6h254.4v15.6c0 11.5 9.4 20.8 20.8 20.8 11.5 0 19.8-9.4 20.8-20.8v-22.2c12.5-7.8 20.8-21.6 20.8-37.2V91.8h13.6v71.9c0 11.5 9.4 20.8 20.8 20.8s20.8-9.4 20.8-20.8V70.9c.4-11.5-9-20.8-20.5-20.8zm-370 362.7c-2.1 0-3.1-1-3.1-3.1v-87.6h298.1v87.6c0 2.1-1 3.1-3.1 3.1h-291.9zm0-372.1h291.9c2.1 0 3.1 1 3.1 3.1v236.6h-298.2V43.8c0-2.1 1.1-3.1 3.2-3.1z" />
  </svg>
);

export const Monorail = () => (
  <svg
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    className="iconify iconify--emojione-monotone"
  >
    <path
      d="M2 45.466h7.5V62h1.348V45.466h4.687V62h1.466V45.466H28.25V62h1.348V45.466h4.687V62h1.465V45.466H47V62h1.348V45.466h4.687V62H54.5V45.466H62V44.06H2zM4.813 36.527h3.75v1.875h-3.75zM12.313 36.527h3.75v1.875h-3.75z"
      fill="currentColor"
    />
    <path
      d="M62 24.092H9.741c0-.648.23-1.406.632-2.232 3.065-.843 14.053-3.727 20.743-3.727 2.273 0 4.208-6.999 4.208-10.316 0-2.614-1.008-2.95-4.302-2.993C32.41 4.302 33.67 4 34.707 4H62V2H34.707C26.846 2 7.741 16.728 7.741 24.092c0 2.346 3.78 6.95 9.735 10.124H2v.938h17.406c1.196.53 2.464.995 3.79 1.374h-3.383v1.875h3.75V36.63c1.203.326 2.458.57 3.75.733v1.04h3.75v-.123a25.27 25.27 0 0 0 2.287 1.561H2v3.281h60v-7.528H37.861c-3.092-1.35-5.265-3.32-7.277-5.155-1.336-1.217-2.639-2.396-4.091-3.289H62v-3.058M27.684 35.393c-4.301-.529-8.088-2.146-11.064-4.038h3.747c2.878-.001 4.839 1.781 7.317 4.038"
      fill="currentColor"
    />
    <path
      d="M62 4.816H46.834c-5.57 0 7.242 15.272 12.061 15.272H62V4.816zM37.014 16.415a2.72 2.72 0 0 0 5.438 0v-8.88a2.719 2.719 0 1 0-5.438 0v8.88"
      fill="currentColor"
    />
  </svg>
);

export const Walk = () => (
  <svg viewBox="-97 0 512 512" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="currentColor"
      d="m68.822 394.263 21.259-49.604 51.65 51.65-16.93 34.695a31.985 31.985 0 0 1-6.418 8.877l-63.912 62.324c-12.504 12.193-32.56 12.023-44.961-.275-12.627-12.523-12.708-33.027-.073-45.543l52.492-51.994a32 32 0 0 0 6.893-10.13Zm25.076-180.83 26.489-10.52-18.105 82.627a16 16 0 0 0 3.489 13.846l.28.326L159.363 360l-.167.517.224-.46 32.816 44.244a31.978 31.978 0 0 1 5.28 11.056l18.488 71.534c4.45 17.218 22.205 27.473 39.384 22.87 16.869-4.52 27.116-21.762 22.878-38.705l-21.323-85.252a31.985 31.985 0 0 0-6.018-12.179l-54.38-68.24 16.859-65.748 9.711 25.748a32 32 0 0 0 10.579 14.186l33.912 25.773c14.026 10.66 34.04 7.965 44.762-6.013 10.783-14.057 8.104-34.213-5.99-44.947l-25.107-19.123a31.996 31.996 0 0 1-10.208-13.292l-21.681-52.748a29.167 29.167 0 0 0-14.053-15.086l.034-.135s-25.696-17.436-44.5-22.5c-19.553-5.265-50.639-1.941-54.17-1.538a31.864 31.864 0 0 0-11.834 2.238l-72.866 28.762a10.006 10.006 0 0 0-4.327 3.299l-33.134 44.146c-10.593 14.114-7.735 34.159 6.357 44.782 14.112 10.639 34.204 7.844 44.842-6.268l21.296-28.249a16.015 16.015 0 0 1 6.871-5.239ZM199.363 112c30.928 0 56-25.072 56-56s-25.072-56-56-56-56 25.072-56 56 25.072 56 56 56Z"
    />
  </svg>
);
