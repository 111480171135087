import styled from 'styled-components';

import { formatViewport } from 'shared/getPixelsToViewport';

import { toggleDarkModeStyles } from '../../shared/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const DateLabel = styled.div`
  display: flex;
  font-size: ${formatViewport(26)};
  font-weight: 500;
  margin-bottom: ${formatViewport(12)};
  color: ${toggleDarkModeStyles('white')};
`;

export const EventContainer = styled.div`
  display: flex;
  flex: 1;
  border: ${formatViewport(1)} solid black;
  border-radius: ${formatViewport(8)};
  margin-bottom: ${formatViewport(16)};
  position: relative;
  min-height: ${formatViewport(72)};
  align-items: center;
`;

export const TimeContainer = styled.div`
  position: absolute;
  top: ${formatViewport(16)};
  left: ${formatViewport(12)};
  display: flex;
  width: ${formatViewport(82)};
  height: ${formatViewport(42)};
  justify-content: center;
  align-items: center;
  font-size: ${formatViewport(16)};
  font-weight: 500;
  border: ${formatViewport(1)} solid black;
  border-radius: ${formatViewport(8)};
  background-color: white;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex: 1 0 50%;
  border-radius: ${formatViewport(8)};
  height: fit-content;
  color: #828282;
`;

export const Image = styled.img`
  object-fit: contain;
  width: 100%;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 50%;
  padding-top: ${formatViewport(10)};
  padding-right: ${formatViewport(16)};
  padding-bottom: ${formatViewport(4)};
  padding-left: ${formatViewport(40)};
  color: #828282;
`;

export const Header = styled.div`
  font-weight: 500;
  font-size: ${formatViewport(18)};
  margin-bottom: ${formatViewport(10)};
`;

export const ImagePlaceholder = styled(Header)`
  display: flex;
  flex: 1;
  justify-content: center;
  padding-left: ${formatViewport(112)};
  align-items: center;
  margin-bottom: 0;
`;

export const SingleInfo = styled.div`
  display: flex;
  margin-bottom: ${formatViewport(8)};
  align-items: center;
`;

export const IconContainer = styled.div`
  display: flex;
  flex: 0 0 ${formatViewport(20)};
  justify-content: center;
  margin-right: ${formatViewport(12)};

  svg {
    width: ${formatViewport(20)};
    height: ${formatViewport(20)};
  }
`;

export const Text = styled.div`
  font-size: ${formatViewport(10)};
  font-weight: 500;
`;

export const DescriptionText = styled.div`
  font-size: ${formatViewport(10)};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
`;

export const AvatartsContainer = styled.div`
  display: flex;
  position: relative;
  margin-left: ${formatViewport(10)};
`;

export const Avatar = styled.img<{ ind: number }>`
  position: absolute;
  top: -${formatViewport(10)};
  left: ${({ ind }) => formatViewport(14 * ind)};
  width: ${formatViewport(20)};
  height: ${formatViewport(20)};
  object-fit: cover;
  border-radius: 50%;
`;
