import { Schema } from 'shared/defaultWidgetsSchema';
import { ColumnOptions, WidgetType } from 'shared/types';

import { TemplatesTitles } from './types';

export const SCHEMA1: Schema = [
  [
    {
      announcements: null,
      automatedEventFlyerTime: null,
      columnOptions: ColumnOptions.FullColumn,
      coversTilesIndexes: [],
      isCovered: false,
      isFullColumn: false,
      isHalfColumn: false,
      isHorizontalColumn: false,
      isSquareColumn: false,
      rotatingGallery: null,
      type: WidgetType.Calendar,
    },
    {
      announcements: null,
      automatedEventFlyerTime: null,
      columnOptions: ColumnOptions.FullColumn,
      coversTilesIndexes: [],
      isCovered: false,
      isFullColumn: false,
      isHalfColumn: false,
      isHorizontalColumn: false,
      isSquareColumn: false,
      rotatingGallery: null,
      type: WidgetType.Weather,
    },
    {
      announcements: null,
      automatedEventFlyerTime: null,
      columnOptions: ColumnOptions.FullColumn,
      coversTilesIndexes: [],
      isCovered: false,
      isFullColumn: false,
      isHalfColumn: false,
      isHorizontalColumn: false,
      isSquareColumn: false,
      rotatingGallery: null,
      type: WidgetType.Announcements,
    },
  ],
  [
    {
      announcements: null,
      automatedEventFlyerTime: null,
      columnOptions: null,
      coversTilesIndexes: [],
      isCovered: false,
      isFullColumn: false,
      isHalfColumn: false,
      isHorizontalColumn: false,
      isSquareColumn: false,
      rotatingGallery: null,
      type: WidgetType.None,
    },
    {
      announcements: null,
      automatedEventFlyerTime: null,
      columnOptions: null,
      coversTilesIndexes: [],
      isCovered: false,
      isFullColumn: false,
      isHalfColumn: false,
      isHorizontalColumn: false,
      isSquareColumn: false,
      rotatingGallery: null,
      type: WidgetType.None,
    },
    {
      announcements: null,
      automatedEventFlyerTime: null,
      columnOptions: null,
      coversTilesIndexes: [],
      isCovered: false,
      isFullColumn: false,
      isHalfColumn: false,
      isHorizontalColumn: false,
      isSquareColumn: false,
      rotatingGallery: null,
      type: WidgetType.None,
    },
  ],
];

export const SCHEMA2: Schema = [
  [
    {
      announcements: null,
      automatedEventFlyerTime: null,
      columnOptions: ColumnOptions.HalfColumn,
      coversTilesIndexes: [],
      isCovered: false,
      isFullColumn: false,
      isHalfColumn: false,
      isHorizontalColumn: false,
      isSquareColumn: false,
      rotatingGallery: null,
      type: WidgetType.Calendar,
    },
    {
      announcements: null,
      automatedEventFlyerTime: null,
      columnOptions: ColumnOptions.HorizontalColumn,
      coversTilesIndexes: [],
      isCovered: false,
      isFullColumn: false,
      isHalfColumn: false,
      isHorizontalColumn: false,
      isSquareColumn: false,
      rotatingGallery: null,
      type: WidgetType.Weather,
    },
    {
      announcements: null,
      automatedEventFlyerTime: null,
      columnOptions: null,
      coversTilesIndexes: [],
      isCovered: false,
      isFullColumn: false,
      isHalfColumn: false,
      isHorizontalColumn: false,
      isSquareColumn: false,
      rotatingGallery: null,
      type: WidgetType.None,
    },
  ],
  [
    {
      announcements: null,
      automatedEventFlyerTime: null,
      columnOptions: ColumnOptions.HalfColumn,
      coversTilesIndexes: [],
      isCovered: false,
      isFullColumn: false,
      isHalfColumn: false,
      isHorizontalColumn: false,
      isSquareColumn: false,
      rotatingGallery: null,
      type: WidgetType.Announcements,
    },
    {
      announcements: null,
      automatedEventFlyerTime: null,
      columnOptions: null,
      coversTilesIndexes: [],
      isCovered: false,
      isFullColumn: false,
      isHalfColumn: false,
      isHorizontalColumn: false,
      isSquareColumn: false,
      rotatingGallery: null,
      type: WidgetType.None,
    },
    {
      announcements: null,
      automatedEventFlyerTime: null,
      columnOptions: null,
      coversTilesIndexes: [],
      isCovered: false,
      isFullColumn: false,
      isHalfColumn: false,
      isHorizontalColumn: false,
      isSquareColumn: false,
      rotatingGallery: null,
      type: WidgetType.None,
    },
  ],
];

export const TEMPLATES: { id: number; schema: Schema | null; title: TemplatesTitles }[] = [
  {
    id: 0,
    schema: null,
    title: TemplatesTitles.currentTemplate,
  },
  {
    id: 1,
    schema: SCHEMA1,
    title: TemplatesTitles.template1,
  },
  {
    id: 2,
    schema: SCHEMA2,
    title: TemplatesTitles.template2,
  },
];
