import { useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { pdfjs } from 'react-pdf';
import { useQuery } from 'react-query';
import urljoin from 'url-join';

import getPdfs from 'api/getPdfs';

import { usePopupContext } from 'providers/PopupProvider/PopupProvider';

import { colors } from 'shared/colors';
import endpoints from 'shared/endpoints';
import { Column, Row } from 'shared/types';

import useWidgetsContext from '../../hooks/useWidgetsContext';
import { updateTileInSchema } from '../../shared/Widget/tileHandler';
import { Tile } from '../../shared/defaultWidgetsSchema';
import PdfWidgetPopup from '../Popups/PdfWidgetPopup';
import { DEFAULT_UPLOAD_IMAGE_OPTION } from '../Popups/UploadImagesPopup/constant';
import { useGetNextImageIndex } from '../RotatingGallery/hooks';
import { isSelectedTileEqualToCurrent } from '../RotatingGallery/utils';

import * as Styles from './PdfWidget.styles';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfWidget = ({ tileNumber }: { tileNumber: [Column, Row] }) => {
  const { onWidgetSchemaChange, widgetsSchema } = useWidgetsContext();

  const {
    isLoading,
    data: pdfs,
    // isError, // needs ticket
    // error,
  } = useQuery(
    'pdfs',
    getPdfs,
    { refetchInterval: 60_000 }, // 1min
  );

  const {
    onClose,
    open: { pdfsPopup },
    activeTile,
  } = usePopupContext();

  const isSelectedPdfWidgetCanBeOpen = activeTile
    ? isSelectedTileEqualToCurrent(activeTile, tileNumber) && pdfsPopup
    : pdfsPopup;

  const [column, row] = tileNumber;
  const [rotationDuration, setRotationDuration] = useState(
    widgetsSchema[row][column].automatedEventFlyerTime || DEFAULT_UPLOAD_IMAGE_OPTION,
  );

  const { activeIndex } = useGetNextImageIndex({
    disabled: isLoading,
    duration: rotationDuration,
    files: pdfs ?? [],
  });

  const onSave = () => {
    const currentTileSchema: Tile = { ...widgetsSchema[row][column], automatedEventFlyerTime: rotationDuration };
    const updatedSchema = updateTileInSchema(currentTileSchema, row, column, widgetsSchema);
    onWidgetSchemaChange(updatedSchema);
  };

  const renderPdf = () => {
    if (isLoading) {
      return <TailSpin color={colors.main} />;
    }

    if (!pdfs?.length || typeof pdfs?.[activeIndex]?.file === 'undefined') {
      return <Styles.NoPdfTitle>No announcements to show</Styles.NoPdfTitle>;
    }

    return (
      <Styles.PdfsList>
        {pdfs.map((_, i) => (
          <Styles.PdfWrapper activeIndex={activeIndex}>
            <Styles.PdfDocument
              file={urljoin(process.env.REACT_APP_EV_URL, endpoints.EVENT_IMAGE, pdfs?.[i]?.file)}
              loading={<TailSpin color={colors.main} />}
            >
              <Styles.PdfPage pageNumber={1} renderMode="svg" renderAnnotationLayer={false} />
            </Styles.PdfDocument>
          </Styles.PdfWrapper>
        ))}
      </Styles.PdfsList>
    );
  };

  return (
    <>
      {isSelectedPdfWidgetCanBeOpen ? (
        <PdfWidgetPopup
          defaultDurationValue={rotationDuration}
          open={isSelectedPdfWidgetCanBeOpen}
          setRotationDuration={setRotationDuration}
          onClose={() => onClose('pdfsPopup')}
          onSubmit={onSave}
        />
      ) : null}
      <Styles.Container>{renderPdf()}</Styles.Container>
    </>
  );
};

export default PdfWidget;
