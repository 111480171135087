import * as Styles from './WeatherCurrentDetail.styles';
import * as Types from './WeatherCurrentDetail.types';

const WeatherCurrentDetail = ({ data, icon, label }: Types.Props) => (
  <Styles.Container>
    {icon}
    <Styles.TextContainer>
      <div>{data}</div>
      <div>{label}</div>
    </Styles.TextContainer>
  </Styles.Container>
);

export default WeatherCurrentDetail;
