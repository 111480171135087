import { ColumnOptions } from 'shared/types';

export const COLUMN_HEIGHT = {
  [ColumnOptions.FullColumn]: 'calc(100% - 40px) !important',
  [ColumnOptions.HalfColumn]: 'calc(50% - 30px) !important',
  [ColumnOptions.HorizontalColumn]: 'calc(50% - 30px) !important',
  [ColumnOptions.SquareColumn]: 'calc(100% - 40px) !important',
};

export const COLUMN_WIDTH = {
  [ColumnOptions.FullColumn]: 'calc((100vw / 3) - 25px) !important',
  [ColumnOptions.HalfColumn]: 'calc((100vw / 3) - 25px) !important',
  [ColumnOptions.HorizontalColumn]: 'calc(66% - 22px) !important',
  [ColumnOptions.SquareColumn]: 'calc(66% - 22px) !important',
};
