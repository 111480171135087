import { differenceInMilliseconds } from 'date-fns';
import { useEffect, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useQuery } from 'react-query';
import urljoin from 'url-join';

import getWeather from 'api/getWeather';

import useWidgetsContext from 'hooks/useWidgetsContext';

import { ReactComponent as HumidityIcon } from 'images/humidityIcon.svg';
import { ReactComponent as RainIcon } from 'images/rainIcon.svg';
import { ReactComponent as TempIcon } from 'images/tempIcon.svg';
import { ReactComponent as WindIcon } from 'images/windIcon.svg';

import { colors } from 'shared/colors';

import WeatherCurrentDetail from '../WeatherCurrentDetail';
import WeatherHourlyDetail from '../WeatherHourlyDetail';

import * as Styles from './WeatherWidget.styles';
import * as Utils from './WeatherWidget.utils';

const WeatherWidget = () => {
  const { timeZone, buildingAddress, buildingId } = useWidgetsContext();
  const [weatherRequestInterval, setWeatherRequestInerval] = useState(10_000_000);
  const [currentDate, setCurrentDate] = useState(new Date());

  const {
    isLoading,
    data: weather,
    isError, // needs ticket
    // error,
  } = useQuery('weather', () => getWeather(buildingId), {
    refetchInterval: weatherRequestInterval,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (weather && weatherRequestInterval !== 3_600_000) {
      if (weatherRequestInterval === 10_000_000) {
        const diff = differenceInMilliseconds(new Date(weather.hourly[0].date), new Date());
        setWeatherRequestInerval(diff + 60_000); // + 1min in case backend is slow
      } else {
        setWeatherRequestInerval(3_600_000);
      }
    }
  }, [weather]);

  useEffect(() => {
    const interval = setInterval(() => setCurrentDate(new Date()), 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const renderWeather = () => {
    if (isLoading) {
      return (
        <Styles.SpinnerContainer>
          <TailSpin color={colors.main} />
        </Styles.SpinnerContainer>
      );
    }

    if (isError) {
      return <div>Error occurred</div>;
    }

    return (
      <>
        <Styles.TopContainer>
          <Styles.MainContainer>
            <Styles.TemperatureTime>
              <Styles.Temperature>
                <Styles.TemperatureIconWrapper>
                  <Styles.MainIcon
                    src={urljoin('http://openweathermap.org/img/wn/', `${weather!.current?.iconId}@4x.png`)}
                  />
                  <Styles.Degree>{Math.round(weather!.current.temperature)}&deg;F</Styles.Degree>
                </Styles.TemperatureIconWrapper>
                <Styles.Label>{buildingAddress}</Styles.Label>
              </Styles.Temperature>
              <Styles.Time>
                <Styles.ClockContainer>
                  <Styles.ClockText>{Utils.getCurrentTime(currentDate, timeZone)}</Styles.ClockText>
                  <Styles.ClockSuffix>{Utils.getAMPM(currentDate, timeZone)}</Styles.ClockSuffix>
                </Styles.ClockContainer>
                <Styles.Label>{Utils.getFullDay(currentDate, timeZone)}</Styles.Label>
              </Styles.Time>
            </Styles.TemperatureTime>
          </Styles.MainContainer>
          <Styles.CurrentDetailsContainer>
            <WeatherCurrentDetail icon={<WindIcon />} data={`${weather!.current.windSpeed} mph`} label="Wind" />
            <WeatherCurrentDetail
              icon={<RainIcon />}
              data={`${weather!.current.chanceOfRain * 100}%`}
              label="Chance of rain"
            />
            <WeatherCurrentDetail icon={<TempIcon />} data={`${weather!.current.pressure} mbar`} label="Pressure" />
            <WeatherCurrentDetail icon={<HumidityIcon />} data={`${weather!.current.humidity}%`} label="Humidity" />
          </Styles.CurrentDetailsContainer>
        </Styles.TopContainer>
        <Styles.Splitter />
        <Styles.BottomContainer>
          <WeatherHourlyDetail
            label="Now"
            icon={weather!.current.iconId}
            temp={weather!.current.temperature}
            chanceOfRain={weather!.current.chanceOfRain}
          />
          {weather?.hourly.map((info) => (
            <WeatherHourlyDetail
              key={info.date}
              label={Utils.getTimezonedHoursAndMinutes(info.date, timeZone, 'h:mm a')}
              icon={info.iconId}
              temp={info.temperature}
              chanceOfRain={info.chanceOfRain}
            />
          ))}
        </Styles.BottomContainer>
      </>
    );
  };

  return <Styles.Container>{renderWeather()}</Styles.Container>;
};

export default WeatherWidget;
