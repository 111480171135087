import styled from 'styled-components';

import { colors } from 'shared/colors';
import { formatViewport } from 'shared/getPixelsToViewport';

export const PhotoWrapper = styled.div`
  border-radius: 6px;
  flex: 0 0 ${formatViewport(129)};
  height: ${formatViewport(129)};
  position: relative;
`;

export const Photo = styled.img`
  border-radius: 6px;
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

export const RemovePhotoIcon = styled.div`
  align-items: center;
  background: ${colors.white};
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  height: ${formatViewport(32)};
  justify-content: center;
  margin: ${formatViewport(6)};
  position: absolute;
  right: 0;
  top: 0;
  width: ${formatViewport(32)};

  svg {
    width: ${formatViewport(15.32)};
    height: ${formatViewport(15.32)};
  }
`;

export const PdfDocument = styled.div`
  border-radius: 6px;
  height: ${formatViewport(129)};
  object-fit: contain;
  overflow: hidden;
  width: ${formatViewport(129)};

  & > object {
    width: 100% !important;
    height: 100% !important;
  }
`;
