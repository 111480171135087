import styled from 'styled-components';

import { formatViewport } from 'shared/getPixelsToViewport';
import { toggleDarkModeStyles } from 'shared/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${toggleDarkModeStyles('white')};
`;

export const Label = styled.div`
  font-size: ${formatViewport(16)};
`;

export const Image = styled.img`
  width: ${formatViewport(36)};
`;

export const SmallText = styled.div`
  font-size: ${formatViewport(12.8)};
`;
