import { Document, Page } from 'react-pdf';
import styled from 'styled-components';

import { colors } from 'shared/colors';
import { formatViewport } from 'shared/getPixelsToViewport';

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
  border-radius: ${formatViewport(8)};
  position: relative;
`;

export const WaterMark = styled.div`
  position: absolute;
  z-index: 2;
  top: ${formatViewport(24)};
  left: ${formatViewport(32)};
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 100%;
  flex: 0 0 100%;
`;

export const ImageWrapper = styled.div<{ translateValue: number }>`
  height: 100%;
  position: relative;
  transform: ${({ translateValue }) => `translateX(-${translateValue * 100}%);`}
  transition: transform 0.5s ease;
  width: 100%;
`;

export const Image = styled.img`
  height: 100%;
  object-fit: cover;
  position: absolute;
  width: 100%;
`;

export const ImagePdfDocument = styled(Document)`
  height: 100% !important;
  object-fit: cover;
  position: absolute;
  width: 100%;

  & > .react-pdf__Page {
    width: 100% !important;
    height: 100% !important;
  }

  & .react-pdf__Page__svg {
    display: flex !important;
    height: 100% !important;
    justify-content: center !important;
    width: 100% !important;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;

export const ImagePdfPage = styled(Page)``;

export const Steps = styled.div`
  align-items: center;
  bottom: ${formatViewport(6)};
  display: flex;
  gap: ${formatViewport(6)};
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 1;
`;

export const Step = styled.div<{ active: boolean }>`
  background: ${({ active }) => (active ? 'white' : 'rgba(255,255,255, 0.5)')};
  border-radius: 100%;
  height: ${formatViewport(10)};
  width: ${formatViewport(10)};
`;

export const Content = styled.div<{ translateValue: number }>`
  background: ${colors.black};
  color: ${colors.white};
  display: flex;
  flex-direction: column;
  gap: ${formatViewport(16)};
  padding: ${formatViewport(31)};
  transform: ${({ translateValue }) => `translateX(-${translateValue * 100}%);`}                 
  transition: transform 0.5s ease;
`;

export const Title = styled.div`
  color: ${colors.white};
  font-size: ${formatViewport(32)};
  font-weight: 700;
`;

export const Description = styled.div`
  color: ${colors.white};
  font-size: ${formatViewport(24)};
`;
