import { AxiosError } from 'axios';
import { debounce } from 'lodash';
import { createContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import getUser from 'api/getUser';

import {
  isFullColumn,
  isHalfColumn,
  isHorizontalColumn,
  isSquareColumn,
  updateTileInSchema,
} from 'shared/Widget/tileHandler';
import { Schema, schema as initialSchema } from 'shared/defaultWidgetsSchema';
import { Column, ColumnOptions, Row, User, WidgetType } from 'shared/types';

import getBuilding from '../../api/getBuilding';
import getSchema from '../../api/getSchema';
import { updateTvAppSchema } from '../../api/updateWidgetSchema';
import { getBuildingImageLink } from '../../shared/fetchBuildingImage';
import { getLocationIdFromUrl } from '../../shared/getLocationIdFromUrl';

import * as Types from './WidgetsProvider.types';

export const WidgetsContext = createContext<Types.IWidgetsContext | null>(null);

const WidgetsProvider = ({ children }: Types.Props) => {
  const [widgetsSchema, setWidgetsSchema] = useState(initialSchema);
  const [footerLogo, setFooterLogo] = useState<string | null>(null);
  const [timeZone, setTimeZone] = useState('');
  const [buildingName, setBuildingName] = useState('');
  const [buildingId, setBuildingId] = useState<number | null>(null);
  const [buildingAddress, setBuildingAddress] = useState('');
  const [isEditable, setIsEditable] = useState(false);
  const [userData, setUserData] = useState<User>();

  const { isLoading, data: tvAppSchema } = useQuery(
    'tv-app-schema',
    () => getSchema(getLocationIdFromUrl(window.location.href)),
    {
      staleTime: Number.POSITIVE_INFINITY,
    },
  );

  const { isLoading: isBuildingLoading, data: building } = useQuery(
    `tv-app-building-${tvAppSchema?.buildingId}`,
    () => getBuilding(tvAppSchema?.buildingId),
    {
      staleTime: Number.POSITIVE_INFINITY,
    },
  );

  const locationId = getLocationIdFromUrl(window.location.href);

  const updateSchema = useMutation({
    mutationFn: ({
      locationId,
      widgetsSchema,
      logo,
    }: {
      locationId: string;
      widgetsSchema: Schema;
      logo: string | null;
    }) => updateTvAppSchema(locationId, widgetsSchema, logo),
    onError: (error: AxiosError) => {
      if (!error.response) {
        return;
      }
      const statusCode = error.response.status;
      const tokenRelatedStatusCodes = [401, 403, 400, 419, 498];
      if (tokenRelatedStatusCodes.includes(statusCode)) {
        setIsEditable(false);
      }
    },
    onSuccess: () => {
      setIsEditable(true);
    },
  });

  useEffect(() => {
    if (isLoading || isBuildingLoading) return;
    if (!tvAppSchema || !building) return;

    setBuildingName(building.name);
    setTimeZone(building.timezone);
    setBuildingAddress(building.address);
    setBuildingId(tvAppSchema.buildingId);

    const fallbackSchema = {
      schema: initialSchema,
      logo: null,
    };
    const { schema, logo } = tvAppSchema.schema ? JSON.parse(tvAppSchema.schema) : fallbackSchema;
    setWidgetsSchema(schema);
    setFooterLogo(logo);
  }, [tvAppSchema, isLoading, isBuildingLoading]);

  const handleSwitchWidget = (
    column: Column,
    row: Row,
    type: WidgetType,
    columnOptions: ColumnOptions,
    schema: Schema,
  ) => {
    const currentWidgetsSchema = widgetsSchema[row][column];
    const additionalTileData = {
      announcements: currentWidgetsSchema.announcements,
      automatedEventFlyerTime: currentWidgetsSchema.automatedEventFlyerTime,
      rotatingGallery: currentWidgetsSchema.rotatingGallery,
    };
    const selectedTile = initialSchema[row][column];
    if (isFullColumn(columnOptions)) {
      const updatedSelectedTile = {
        ...selectedTile,
        ...additionalTileData,
        columnOptions,
        isFullColumn: true,
        type,
      };

      return updateTileInSchema(updatedSelectedTile, row, column, schema);
    }

    if (isHalfColumn(columnOptions)) {
      const updatedSelectedTile = {
        ...selectedTile,
        ...additionalTileData,
        columnOptions,
        isHalfColumn: true,
        type,
      };
      return updateTileInSchema(updatedSelectedTile, row, column, schema);
    }

    if (isHorizontalColumn(columnOptions)) {
      const updatedSelectedTile = {
        ...selectedTile,
        ...additionalTileData,
        columnOptions,
        isHorizontalColumn: true,
        type,
      };
      return updateTileInSchema(updatedSelectedTile, row, column, schema);
    }

    if (isSquareColumn(columnOptions)) {
      const updatedSelectedTile = {
        ...selectedTile,
        ...additionalTileData,
        columnOptions,
        isSquareColumn: true,
        type,
      };
      return updateTileInSchema(updatedSelectedTile, row, column, schema);
    }

    return schema;
  };

  const onWidgetChange = (column: Column, row: Row, type: WidgetType, columnOptions: ColumnOptions) => {
    setWidgetsSchema((prevWidgetSchema) => handleSwitchWidget(column, row, type, columnOptions, prevWidgetSchema));
  };

  const onWidgetSchemaChange = (newSchema: Schema) => {
    setWidgetsSchema(newSchema);
  };

  const debouncedUpdateSchema = debounce(updateSchema.mutate, 500);

  const onFooterLogoChange = (newFooterLogo: string | null) => {
    setFooterLogo(newFooterLogo);
  };

  useEffect(() => {
    (async () => {
      setUserData(await getUser());
    })();
  }, []);

  useEffect(() => {
    updateSchema.mutate({ locationId, widgetsSchema, logo: footerLogo });
    return () => {
      debouncedUpdateSchema.cancel();
    };
  }, [locationId, widgetsSchema, footerLogo]);

  return (
    <WidgetsContext.Provider
      value={{
        buildingAddress,
        buildingId,
        buildingName,
        footerLogo,
        buildingLogo: building?.logo ? getBuildingImageLink(building.logo) : null,
        isPMDataLoading: isLoading,
        timeZone,
        widgetsSchema,
        isEditable,
        onWidgetChange,
        onFooterLogoChange,
        onWidgetSchemaChange,
        userData,
      }}
    >
      {children}
    </WidgetsContext.Provider>
  );
};

export default WidgetsProvider;
