import styled from 'styled-components';

import { formatViewport } from 'shared/getPixelsToViewport';
import { toggleDarkModeStyles } from 'shared/theme';

export const Container = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: ${formatViewport(24)};
    height: ${formatViewport(24, 'vh')};
  }

  path {
    fill: ${toggleDarkModeStyles('white')};
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${formatViewport(8)};
  font-size: ${formatViewport(15.36)};
`;
