import { CalendarGroupedEvents } from 'shared/types';

import { axiosInstance } from '../axiosInstance';

const getCalendarEvents = async (buildingId: number | null) => {
  if (!buildingId) return;
  const { data } = await axiosInstance.get<CalendarGroupedEvents[]>(`/training-for-tv/${buildingId}?limit=10`);
  return data;
};

export default getCalendarEvents;
