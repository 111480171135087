import { FormControlLabel, FormGroup } from '@mui/material';
import React, { useRef, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useLocalStorage } from 'usehooks-ts';

import * as PopupStyles from 'components/Popups/commonPopupStyles';

import { useRemovePhotos } from 'hooks/useRemovePhotos';
import { useSavePhotos } from 'hooks/useSavePhotos';
import useWidgetsContext from 'hooks/useWidgetsContext';

import { Cross } from 'images/svg';

import { useSettings } from 'providers/SettingsProvider/SettingsProvider';

import { Schema } from 'shared/defaultWidgetsSchema';
import { extractIdFromUrl } from 'shared/extractIdFromUrl';

import * as S from './styles';
import FormDropdown from '../../FormDropdown';
import { useUploadImagesDropzone } from '../../UploadImages/hooks';
import { UploadImageFile } from '../../UploadImages/types';
import Popup from '../Popup.component';
import { TEMPLATES } from './constant';
import { TemplatesTitles } from './types';

const SettingsPopup = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const { hideLogo, darkMode, onSetDarkMode, onSetHideLogo } = useSettings();
  const { onWidgetSchemaChange, onFooterLogoChange, footerLogo } = useWidgetsContext();
  const [schema, setSchema] = useLocalStorage<{ schema: Schema | null; title: TemplatesTitles }>(
    'tv-app-widget-settings-schema',
    {
      schema: null,
      title: TemplatesTitles.currentTemplate,
    },
  );
  const [logo, setLogo] = useState<UploadImageFile[]>([]);
  const logoToRemove = useRef<string | null>(null);
  const { getRootProps, getInputProps } = useUploadImagesDropzone({
    files: logo,
    setFiles: setLogo,
    maxLength: 1,
  });

  const fileName = logo.at(-1)?.name;
  const addLogoText = footerLogo ? 'Edit a Logo' : 'Add a Logo';

  const { deletePhotos, isLoading: isDeletingLoading } = useRemovePhotos({});

  const { savePhotos, isLoading: isSavingPhotosLoading } = useSavePhotos({
    onSuccess: (filesSavedInDB) => {
      const image = filesSavedInDB.at(0);
      if (image) {
        onFooterLogoChange(image.preview);
      }
      onClose();
    },
  });

  const isLoading = isDeletingLoading || isSavingPhotosLoading;

  if (!open) {
    return null;
  }

  const applyChanges = () => {
    const selectedLogo = logo.at(-1);
    if (selectedLogo) {
      savePhotos([selectedLogo]);
    }
    if (schema.schema) {
      onWidgetSchemaChange(schema.schema);
      onClose();
    }

    if (!selectedLogo) {
      onClose();
    }

    setLogo([]);
  };

  const onChangeSchema = (title: unknown) => {
    if (title === TemplatesTitles.currentTemplate) {
      setSchema({
        schema: null,
        title: TemplatesTitles.currentTemplate,
      });
      return;
    }

    const schema = TEMPLATES.find((template) => template.title === title);
    if (schema) {
      setSchema({ schema: schema.schema, title: schema.title });
    }
  };

  const onPopupClose = () => {
    setSchema({
      schema: null,
      title: TemplatesTitles.currentTemplate,
    });
    onClose();
  };

  const onRemoveFile = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    if (logoToRemove.current) {
      deletePhotos([logoToRemove.current]);
      onFooterLogoChange(null);
    } else if (footerLogo) {
      const id = extractIdFromUrl(footerLogo);
      if (id) {
        deletePhotos([id]);
        onFooterLogoChange(null);
      }
    }
    setLogo([]);
  };

  return (
    <Popup portalName="settings-portal">
      <S.OverlayWrapper>
        <PopupStyles.ModalContent>
          <PopupStyles.TopBarWrapper>
            <PopupStyles.TopBarTitle>Settings</PopupStyles.TopBarTitle>
            <PopupStyles.CrossButton onClick={onPopupClose}>
              <Cross />
            </PopupStyles.CrossButton>
          </PopupStyles.TopBarWrapper>
          <S.Content>
            <FormGroup>
              <FormControlLabel
                control={<S.SwitchControl checked={hideLogo} />}
                defaultChecked
                label={<S.Label>Hide Logo</S.Label>}
                onChange={(_, checked) => onSetHideLogo(checked)}
              />
              <FormControlLabel
                control={<S.SwitchControl checked={darkMode} />}
                label={<S.Label>Dark Mode</S.Label>}
                onChange={(_, checked) => onSetDarkMode(checked)}
              />
            </FormGroup>
            <S.UploadLogoWrapper>
              <S.TemplateTitle>Upload a custom logo:</S.TemplateTitle>
              <PopupStyles.AddButton {...getRootProps()}>
                <input {...getInputProps()} />
                <PopupStyles.Wrapper>
                  <S.UploadFile>
                    <S.UploadFileText>{fileName || addLogoText}</S.UploadFileText>
                  </S.UploadFile>
                  {fileName || footerLogo ? (
                    <PopupStyles.RemoveWrapper onClick={onRemoveFile}>
                      <PopupStyles.Remove />
                    </PopupStyles.RemoveWrapper>
                  ) : null}
                </PopupStyles.Wrapper>
              </PopupStyles.AddButton>
            </S.UploadLogoWrapper>
            <S.TemplateTitle>Templates:</S.TemplateTitle>
            <FormDropdown
              defaultValue={schema.title}
              fullWidth
              renderOptions={TEMPLATES.map(({ title, id }) => (
                <S.StyledMenuItem key={id} value={title}>
                  <S.ListItem primary={title} />
                </S.StyledMenuItem>
              ))}
              onChange={onChangeSchema}
            />
          </S.Content>
          <PopupStyles.FooterWrapper>
            <PopupStyles.AddButton onClick={applyChanges}>
              {isLoading ? (
                <S.SpinnerWrapper>
                  <TailSpin />
                </S.SpinnerWrapper>
              ) : (
                'Apply Changes'
              )}
            </PopupStyles.AddButton>
          </PopupStyles.FooterWrapper>
        </PopupStyles.ModalContent>
      </S.OverlayWrapper>
    </Popup>
  );
};

export default SettingsPopup;
