export interface WidgetsSchema {
  firstColumn: WidgetColumn;
  secondColumn: WidgetColumn;
  thirdColumn: WidgetColumn;
}

export interface WidgetColumn {
  isFullColumn: boolean;
  firstRow: WidgetType;
  secondRow: WidgetType;
}

export enum WidgetType {
  None = 'None',
  Perks = 'Neighborhood perks',
  Pdf = 'Automated Event Flyer Rotation',
  Calendar = 'Calendar',
  Weather = 'Weather',
  Transportation = 'Transportation',
  RotatingGallery = 'Image/PDF Rotating Gallery',
  Announcements = 'Announcements',
}

export enum Column {
  First,
  Second,
  Third,
}

export enum Row {
  First,
  Second,
}

export interface PropertyManagerData {
  buildingId: {
    id: number;
    name: string;
    timezone: string;
    address: string;
    tvAppSchema: string;
  };
  user: {
    email: string;
  };
}

export interface CalendarEventData {
  name: string;
  description?: string;
  place?: string;
  capacity: number;
  taken: number;
  cost?: number;
  clientAvatars: string[];
  date: string;
  image: string;
  timezone: string;
}

export interface CalendarGroupedEvents {
  date: string;
  calendarEvents: CalendarEventData[];
}

export interface PdfData {
  name: string;
  date: number;
  file: string;
  id: number;
}

export interface FeedPostData {
  id: number;
  createdAt: number;
  title: string;
  message: string;
  images: string[];
  isMarketing: boolean;
  price: number;
  author: {
    firstName: string;
    lastName: string;
    avatar: string;
  };
}

export interface Member {
  count: number;
  avatars: string[];
}

export interface GroupNotificationsSettings {
  push: boolean;
  email: boolean;
}

export interface PostAuthor {
  email: string;
  firstName: string;
  lastName: string;
  avatar: string;
  role: string;
}

export interface Like {
  author: PostAuthor;
}

export interface PostComment {
  id: number;
  createdAt: number;
  updatedAt: number;
  author: PostAuthor;
  message: string;
  images: string[];
  likes: Like[];
}

export interface Post {
  id: number;
  createdAt: number;
  updatedAt: number;
  title: string;
  message: string;
  images: string[];
  approved: boolean;
  groupId: number;
  isWelcomePost: boolean;
  author: PostAuthor;
  comments: PostComment[];
  likes: Like[];
}

export interface GroupsData {
  id: number;
  createdAt: number;
  updatedAt: number;
  buildingId: number;
  name: string;
  image: string;
  description: string;
  system: boolean;
  members: Member;
  notification: GroupNotificationsSettings;
  joined: boolean;
  posts?: Post[];
}

interface HourWeather {
  date: number;
  temperature: number;
  iconId: string;
  chanceOfRain: number;
}

export interface WeatherData {
  current: {
    date: number;
    temperature: number;
    pressure: number;
    humidity: number;
    windSpeed: number;
    iconId: string;
    chanceOfRain: number;
  };
  hourly: HourWeather[];
}

export interface PerkData {
  id: number;
  name: string;
  tag: string;
  image: string;
  logo: string;
  description: string;
}

export interface User {
  avatar: string;
  firstName: string;
  lastName: string;
}

export enum ColumnOptions {
  FullColumn = 'Full column',
  HalfColumn = 'Half column',
  HorizontalColumn = 'Horizontal column',
  SquareColumn = 'Square column',
}

export const ASPECT_RATION = {
  [ColumnOptions.FullColumn]: '2:3',
  [ColumnOptions.HalfColumn]: '6:4.5',
  [ColumnOptions.HorizontalColumn]: '16.5:6',
  [ColumnOptions.SquareColumn]: '5.5:4',
};
