import { useFormikContext } from 'formik';
import React from 'react';
import { TailSpin } from 'react-loader-spinner';

import * as CommonPopupStyles from 'components/Popups/commonPopupStyles';
import Popup from 'components/Popups/Popup.component';

import * as S from './styles';
import { Cross } from '../../../images/svg';
import { AnnouncementsFormValues } from '../../Announcements/types';
import CreateEditAnnouncements from './CreateEditAnnouncements';
import HandleEditAnnouncements from './components';
import { AnnouncementsPopupProps } from './types';

const NO_EDIT = { title: 'No Edit', id: 21_341_912_321 } as AnnouncementsFormValues;

const AnnouncementsPopup = ({
  columnOptions,
  open,
  createdAnnouncements,
  isLoading,
  onRemoveAnnouncements,
  onRemoveFile,
  onClose,
}: AnnouncementsPopupProps) => {
  const formik = useFormikContext<AnnouncementsFormValues>();
  const { values, submitForm } = formik;
  const { isEditing } = values;

  if (!open) {
    return null;
  }

  return (
    <Popup portalName="announcements-portal">
      <CommonPopupStyles.Overlay>
        <CommonPopupStyles.ModalContent>
          <CommonPopupStyles.TopBarWrapper>
            <CommonPopupStyles.TopBarTitle>
              {isEditing ? 'Edit' : 'Create'} new Announcement
            </CommonPopupStyles.TopBarTitle>
            <CommonPopupStyles.CrossButton onClick={onClose}>
              <Cross />
            </CommonPopupStyles.CrossButton>
          </CommonPopupStyles.TopBarWrapper>
          <S.ContentWrapper>
            {createdAnnouncements.length > 0 ? (
              <HandleEditAnnouncements
                createdAnnouncementsList={[NO_EDIT, ...createdAnnouncements]}
                onRemoveAnnouncements={onRemoveAnnouncements}
              />
            ) : null}
            <CreateEditAnnouncements columnOptions={columnOptions} onRemoveFile={onRemoveFile} />
            <CommonPopupStyles.FooterWrapper>
              <CommonPopupStyles.AddButton onClick={submitForm}>
                {isLoading ? (
                  <S.SpinnerWrapper>
                    <TailSpin />
                  </S.SpinnerWrapper>
                ) : isEditing ? (
                  'Edit'
                ) : (
                  'Create'
                )}
              </CommonPopupStyles.AddButton>
            </CommonPopupStyles.FooterWrapper>
          </S.ContentWrapper>
        </CommonPopupStyles.ModalContent>
      </CommonPopupStyles.Overlay>
    </Popup>
  );
};

export default AnnouncementsPopup;
