import { ListItemText, MenuItem } from '@mui/material';
import React, { useState } from 'react';

import FormDropdown from 'components/FormDropdown';

import { ImageRotationTimeProps } from './types';

import * as S from './ImageRotationTime.styles';

const ImageRotationTime = ({ title, dropdownTitle, defaultValue, onSelect, options }: ImageRotationTimeProps) => {
  const [duration, setDuration] = useState(Number(defaultValue));

  const onHandleSelect = (duration: number) => {
    setDuration(duration);
    onSelect(duration);
  };

  return (
    <S.Wrapper>
      <S.Title>{title}</S.Title>
      <S.DropdownWrapper>
        <S.DropdownTitle>{dropdownTitle}</S.DropdownTitle>
        <FormDropdown
          fullWidth
          value={options.find((el) => el.value === duration)?.text}
          onChange={(value) => onHandleSelect(value as number)}
          renderOptions={options.map(({ value, text }) => (
            <MenuItem key={text} value={value}>
              <ListItemText primary={text} />
            </MenuItem>
          ))}
        />
      </S.DropdownWrapper>
    </S.Wrapper>
  );
};
export default ImageRotationTime;
