import { useEffect } from 'react';

import { AnnouncementsFormValues, VisibilityHours } from 'components/Announcements/types';

import { convertHoursToMilliseconds } from 'shared/convertHoursToMilliseconds';

import { isVisibilityTimeIndefinite } from '../shared/announcements/isVisibilityTimeIndefinite';

export const useSetIntervalRemoveItem = (
  announcements: AnnouncementsFormValues[],
  id: number | null,
  onChangeAnnouncements: (newAnnouncements: AnnouncementsFormValues[]) => void,
  visibilityTime?: number | VisibilityHours.INDEFINITE,
) => {
  const removeElementById = (idToRemove: number) => {
    const filteredAnnouncements = announcements.filter(({ id }) => id !== idToRemove);
    onChangeAnnouncements(filteredAnnouncements);
  };

  useEffect(() => {
    if (isVisibilityTimeIndefinite(visibilityTime)) return;
    if (typeof id !== 'number' || typeof visibilityTime !== 'number') return;

    const interval = setInterval(() => {
      removeElementById(id);
    }, convertHoursToMilliseconds(visibilityTime));

    return () => clearInterval(interval);
  }, [id, visibilityTime, announcements]);
};
