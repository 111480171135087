import styled from 'styled-components';

import { formatViewport } from 'shared/getPixelsToViewport';
import { toggleDarkModeStyles } from 'shared/theme';

export const Page = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  overflow-x: hidden;
  background: ${toggleDarkModeStyles('black')};
`;

export const FullHD = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: calc(100% - 10vh);
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const TilesWrapper = styled.div`
  .class-00 {
    top: 0;
    left: 0;
    position: absolute;
    margin: 20px;
  }

  .class-10 {
    top: 0;
    left: calc((100vw / 3) - 8px);
    position: absolute;
    margin: 20px;
  }

  .class-20 {
    top: 0;
    right: 0;
    position: absolute;
    margin: 20px;
  }

  .class-01 {
    bottom: 0;
    left: 0;
    position: absolute;
    margin: 20px;
  }

  .class-11 {
    bottom: 0;
    left: calc((100vw / 3) - 8px);
    position: absolute;
    margin: 20px;
    background-color: ${toggleDarkModeStyles('widgetBackground')};
  }

  .class-21 {
    bottom: 0;
    right: 0;
    position: absolute;
    margin: 20px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${formatViewport(944)};
`;

export const BuildingName = styled.div`
  position: absolute;
  bottom: ${formatViewport(18)};
  left: ${formatViewport(42)};
  font-size: ${formatViewport(27)};
  font-weight: 500;
  color: ${toggleDarkModeStyles('white')};
  display: flex;
  align-items: center;
`;

export const LogoContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: ${formatViewport(712)};
  height: 0;
  border-bottom: ${formatViewport(64)} solid #1a2a4c;
  border-left: ${formatViewport(64)} solid transparent;
`;

export const EVLogo = styled.img`
  object-fit: contain;
  height: ${formatViewport(30)};
`;

export const LogoText = styled.div`
  font-size: ${formatViewport(12)};
  margin-top: ${formatViewport(2)};
  margin-bottom: ${formatViewport(4)};
  color: white;
`;

export const Footer = styled.div`
  background: yellow;
  display: flex;
  align-items: center;
`;

export const LogoWrapper = styled.div`
  position: absolute;
  bottom: ${formatViewport(18)};
  left: 0;
  right: 0;
  text-align: center;
  font-size: ${formatViewport(27)};
`;

export const BuildingLogo = styled.img`
  object-fit: cover;
  height: ${formatViewport(80)};
`;
export const Logo = styled.img`
  object-fit: contain;
  height: ${formatViewport(50)};
`;
