import React from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useQuery } from 'react-query';

import getCalendarEvents from 'api/getCalendarEvents';

import CalendarEvent from 'components/CalendarEvent';

import useWidgetsContext from 'hooks/useWidgetsContext';

import { colors } from 'shared/colors';

import * as Styles from './CalendarWidget.styles';

const CalendarWidget = () => {
  const { buildingId } = useWidgetsContext();
  const { isLoading, data: groupedEvents } = useQuery('calendar-events', () => getCalendarEvents(buildingId), {
    refetchInterval: 300_000,
    refetchOnWindowFocus: false,
  });

  const renderGroupedEvents = () => {
    if (!groupedEvents) return null;

    return (
      <>
        {groupedEvents.length > 0
          ? groupedEvents.map((entry) => (
              <React.Fragment key={entry.date}>
                {entry.calendarEvents.map((event, ind) => (
                  <CalendarEvent date={entry.date} key={event.date} data={event} isFirstEvent={ind === 0} />
                ))}
              </React.Fragment>
            ))
          : null}
      </>
    );
  };

  return (
    <Styles.Container>
      {isLoading ? (
        <Styles.SpinnerContainer>
          <TailSpin color={colors.main} />
        </Styles.SpinnerContainer>
      ) : (
        renderGroupedEvents()
      )}
    </Styles.Container>
  );
};

export default CalendarWidget;
