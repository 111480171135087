import { useState } from 'react';

import { ReactComponent as DeleteIcon } from 'images/delete.svg';
import { ReactComponent as LabelIcon } from 'images/dropdownlabel.svg';

import { ColumnOptions, WidgetType } from 'shared/types';

import DropdownColumnButton from '../Buttons/DropdownColumnButton';
import { DropdownIcons } from '../Buttons/DropdownColumnButton/types';

import * as Styles from './WidgetDropdown.styles';
import * as Types from './WidgetDropdown.types';

const WidgetDropdown = ({ onOptionClicked }: Types.Props) => {
  const [selected, setSelected] = useState<{ option: WidgetType; nestingLevel: undefined | number }>({
    option: WidgetType.None,
    nestingLevel: undefined,
  });
  const { None, ...exc } = WidgetType;
  const options = Object.values(exc) as Array<Exclude<WidgetType, WidgetType.None>>;

  const onDeleteWidget = () => {
    onOptionClicked(None, ColumnOptions.HalfColumn, true);
  };

  const onMainOptionClicked = (option: Exclude<WidgetType, WidgetType.None>) => {
    switch (option) {
      case WidgetType.Pdf:
        onOptionClicked(option, ColumnOptions.FullColumn);
        break;
      case WidgetType.Weather:
        onOptionClicked(option, ColumnOptions.HalfColumn);
        break;
      case WidgetType.Perks:
      case WidgetType.RotatingGallery:
      case WidgetType.Announcements:
      case WidgetType.Transportation:
      case WidgetType.Calendar:
        setSelected({ option, nestingLevel: undefined });
        break;
      default:
        onOptionClicked(None, ColumnOptions.HalfColumn);
    }
  };

  const renderFilteredColumnTypes = () => {
    if (selected.option === WidgetType.Announcements) {
      return (
        <Styles.SizeOptionContainer>
          <DropdownColumnButton
            icon={DropdownIcons.FullColumn}
            onSelect={() => onOptionClicked(selected.option, ColumnOptions.FullColumn)}
          />
          <DropdownColumnButton
            icon={DropdownIcons.HalfColumn}
            onSelect={() => onOptionClicked(selected.option, ColumnOptions.HalfColumn)}
          />
        </Styles.SizeOptionContainer>
      );
    }

    return (
      <Styles.SizeOptionContainer>
        <DropdownColumnButton
          icon={DropdownIcons.FullColumn}
          onSelect={() => onOptionClicked(selected.option, ColumnOptions.FullColumn)}
        />
        <DropdownColumnButton
          icon={DropdownIcons.HalfColumn}
          onSelect={() => onOptionClicked(selected.option, ColumnOptions.HalfColumn)}
        />
        <DropdownColumnButton
          icon={DropdownIcons.HorizontalColumn}
          onSelect={() => onOptionClicked(selected.option, ColumnOptions.HorizontalColumn)}
        />
        <DropdownColumnButton
          icon={DropdownIcons.SquareColumn}
          onSelect={() => onOptionClicked(selected.option, ColumnOptions.SquareColumn)}
        />
      </Styles.SizeOptionContainer>
    );
  };

  return (
    <Styles.Container>
      {options.map((option) => (
        <div key={option}>
          <Styles.OptionContainer onClick={() => onMainOptionClicked(option)}>
            <Styles.OptionIcon isClicked={selected.option === option}>
              <LabelIcon />
            </Styles.OptionIcon>
            <Styles.OptionText isClicked={selected.option === option}>{option}</Styles.OptionText>
          </Styles.OptionContainer>
          {selected.option === option ? renderFilteredColumnTypes() : null}
        </div>
      ))}
      <Styles.OptionContainer onClick={onDeleteWidget}>
        <Styles.OptionIcon isClicked={false}>
          <DeleteIcon />
        </Styles.OptionIcon>
        <Styles.OptionText isClicked={false}>Delete widget</Styles.OptionText>
      </Styles.OptionContainer>
    </Styles.Container>
  );
};

export default WidgetDropdown;
