import { useEffect, useRef, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useQuery } from 'react-query';

import getPerks from 'api/getPerks';

import Perk from 'components/Perk';

import { colors } from 'shared/colors';

import useWidgetsContext from '../../hooks/useWidgetsContext';

import * as Styles from './PerksWidget.styles';
import * as Utils from './PerksWidget.utils';

const PerksWidget = () => {
  const flex = useRef<HTMLDivElement>(null);
  const [firstVisibleIndex, setFirstVisibleIndex] = useState(0);
  const [breakIndexArray, setBreakIndexArray] = useState([0]);
  const { buildingId } = useWidgetsContext();

  const {
    isLoading,
    data: perks,
    // isError, // needs ticket
    // error,
  } = useQuery(
    'perks',
    () => getPerks(buildingId),
    { refetchInterval: 300_000, refetchOnWindowFocus: false, staleTime: Number.POSITIVE_INFINITY }, // 5min
  );

  useEffect(() => {
    if (isLoading) {
      setBreakIndexArray([0]);
      setFirstVisibleIndex(0);
    }
  }, [isLoading]);

  useEffect(() => {
    if (flex && flex.current && perks) {
      // @ts-ignore
      const children = [...flex.current.children];

      if (children.length > 0) {
        const indexes = Utils.getBreakIndexArray(children as HTMLElement[]);
        setBreakIndexArray(indexes);
      }
    }
  }, [perks]);

  useEffect(() => {
    const interval = setInterval(() => {
      setFirstVisibleIndex((previous) => {
        const currentIndex = breakIndexArray.indexOf(previous);
        return breakIndexArray[(currentIndex + 1) % breakIndexArray.length];
      });
    }, 10_000);

    return () => {
      clearInterval(interval);
    };
  }, [breakIndexArray]);

  return (
    <Styles.Container ref={flex}>
      {isLoading ? (
        <Styles.SpinnerContainer>
          <TailSpin color={colors.main} />
        </Styles.SpinnerContainer>
      ) : (
        perks?.slice(firstVisibleIndex).map((data) => <Perk key={data.id} data={data} />)
      )}
    </Styles.Container>
  );
};

export default PerksWidget;
