import { FormHelperText, ListItemText } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import styled from 'styled-components';

import { ReactComponent as Calendar } from 'images/svg/calendar.svg';

import { formatViewport } from 'shared/getPixelsToViewport';
import { toggleDarkModeStyles } from 'shared/theme';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  gap: ${formatViewport(8)};
`;

export const Column = styled.div`
  flex-direction: column;
  display: flex;
`;

export const DatePickerWrapper = styled(DatePicker)`
  flex: 1;
  & .MuiInputBase-root {
    height: ${formatViewport(36)};
    padding-right: ${formatViewport(10)};
  }

  & .MuiInputBase-root input {
    font-size: ${formatViewport(18)};
    font-weight: 400;
    line-height: ${formatViewport(23)};
    padding-left: ${formatViewport(15)};
    color: ${toggleDarkModeStyles('silver')};
  }

  & .MuiOutlinedInput-notchedOutline {
    border: ${formatViewport(1)} solid ${toggleDarkModeStyles('white')};
  }

  & .MuiOutlinedInput-root:hover {
    fieldset {
      border-color: ${toggleDarkModeStyles('white')};
    }
  }
`;

export const CalendarIcon = styled(Calendar)`
  cursor: pointer;
  flex-shrink: 0;
  height: ${formatViewport(13.3)};
  width: ${formatViewport(12.9)};
  path {
    stroke: ${toggleDarkModeStyles('white')};
  }
`;

export const ListItem = styled(ListItemText)`
  & .MuiTypography-root {
    font-size: ${formatViewport(16)};
    padding: ${formatViewport(2)} ${formatViewport(16)};
  }
`;

export const HelperText = styled(FormHelperText)`
  font-size: ${formatViewport(12)} !important;
  color: red !important;
`;
