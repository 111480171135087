import axios from 'axios';

import { getTokenFromURL } from './shared/getTokenFromURL';

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_EV_API_URL,
});

axiosInstance.interceptors.request.use(async (config) => {
  const token = getTokenFromURL();
  if (config.headers && token) {
    config.headers.token = token;
  }

  return config;
});
