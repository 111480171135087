import { debounce } from '@mui/material';
import { useState } from 'react';

import * as S from './styles';
import SettingsPopup from '../Popups/SettingsPopup';
import { SettingsIcon } from './styles';

const Settings = () => {
  const [openNavigation, setOpenNavigation] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);

  const onOpen = debounce(() => setOpenNavigation(true), 250);
  const onClose = debounce(() => setOpenNavigation(false), 250);

  const onOpenPopup = () => setOpenPopup(true);
  const onClosePopup = () => setOpenPopup(false);

  return (
    <S.Wrapper>
      <SettingsPopup open={openPopup} onClose={onClosePopup} />
      <S.VerticalLine open={openNavigation} onMouseEnter={onOpen} onMouseLeave={onClose}>
        {openNavigation ? (
          <div onClick={onOpenPopup}>
            <SettingsIcon />
          </div>
        ) : null}
      </S.VerticalLine>
    </S.Wrapper>
  );
};

export default Settings;
