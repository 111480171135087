import React from 'react';

import { useSetIntervalRemoveItem } from 'hooks/useSetIntervalRemoveItem';

import * as S from './styles';
import { useGetNextImageIndex } from '../../../RotatingGallery/hooks';
import { UploadImageFile } from '../../../UploadImages/types';
import { AnnouncementsFormValues } from '../../types';

const AnnouncementItem = ({
  activeIndex,
  announcement,
  announcements,
  onChangeAnnouncements,
}: {
  activeIndex: number;
  announcement: AnnouncementsFormValues;
  announcements: AnnouncementsFormValues[];
  onChangeAnnouncements: (newAnnouncements: AnnouncementsFormValues[]) => void;
}) => {
  const { files, id, title, description, visibilityTime } = announcement;
  useSetIntervalRemoveItem(announcements, id, onChangeAnnouncements, visibilityTime);

  const renderImage = (file: UploadImageFile) => {
    if (!file) return;
    if (file.isPdf) {
      return (
        <S.ImagePdfDocument file={file.preview}>
          <S.ImagePdfPage pageNumber={1} renderMode="svg" />
        </S.ImagePdfDocument>
      );
    }

    return <S.Image src={file.preview} />;
  };

  return (
    <S.List>
      <S.ImageWrapper translateValue={activeIndex}>
        {renderImage(files[0])}
        {announcements.length > 1 ? (
          <S.Steps>
            {announcements.map((announcement, i) => (
              <S.Step active={activeIndex === i} />
            ))}
          </S.Steps>
        ) : null}
      </S.ImageWrapper>
      <S.Content translateValue={activeIndex}>
        <S.Title>{title}</S.Title>
        <S.Description>{description}</S.Description>
      </S.Content>
    </S.List>
  );
};

const FullColumnAnnouncement = ({
  announcements,
  onChangeAnnouncements,
}: {
  announcements: AnnouncementsFormValues[];
  onChangeAnnouncements: (newAnnouncements: AnnouncementsFormValues[]) => void;
}) => {
  const { activeIndex } = useGetNextImageIndex({
    disabled: false,
    duration: 2000,
    files: announcements,
  });
  return (
    <S.Wrapper>
      {announcements.map((announcement) => (
        <AnnouncementItem
          key={announcement.id}
          activeIndex={activeIndex}
          announcement={announcement}
          announcements={announcements}
          onChangeAnnouncements={onChangeAnnouncements}
        />
      ))}
    </S.Wrapper>
  );
};

export default FullColumnAnnouncement;
