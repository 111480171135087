import { differenceInMinutes } from 'date-fns';

import { NUMBER_OF_STOPS_PER_STATION } from './constants';
import { DepartureResponse, TransitDeparture, TransitDepartureResponse } from './type';

export const filterDepartures = (departures: DepartureResponse[]): DepartureResponse[] => {
  const filteredDepartures: DepartureResponse[] = [];

  for (const departure of departures) {
    if (!filteredDepartures.some((dep) => dep.transport.headsign === departure.transport.headsign)) {
      filteredDepartures.push({ ...departure, time: differenceInMinutes(new Date(departure.time), new Date()) });
    }
  }

  return filteredDepartures;
};

export const prepareData = (data: TransitDepartureResponse[]) => {
  const result: Record<string, TransitDeparture> = {};
  for (const station of data) {
    const stationName = station.place.name;
    result[stationName] = {
      place: station.place,
      transports: filterDepartures(station.departures).map(({ time, transport }) => ({ ...transport, time })),
    };
  }

  return result;
};

type Transit = [string, TransitDeparture];
export type OutputTransit = Transit[][];

export const createTransitSlides = (
  initialTransit: Transit[],
  output: OutputTransit = [],
  startIndex = 0,
  endIndex = NUMBER_OF_STOPS_PER_STATION,
): OutputTransit => {
  const result = [];

  for (const [stationName, stationInformation] of initialTransit) {
    const { transports } = stationInformation;
    const transportSlice = transports.slice(startIndex, endIndex);
    if (transportSlice.length > 0) {
      result.push([stationName, { ...stationInformation, transports: transportSlice }]);
    }
  }
  if (result.length === 0) {
    return output;
  }
  output.push(result as Transit[]);
  return createTransitSlides(initialTransit, output, endIndex, endIndex + NUMBER_OF_STOPS_PER_STATION);
};

export const isBusArrived = (time: number) => {
  if (time === 0) {
    return 'arrived';
  }
  return time;
};
