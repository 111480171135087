import styled from 'styled-components';

import { formatViewport } from 'shared/getPixelsToViewport';
import { toggleDarkModeStyles } from 'shared/theme';

export const Wrapper = styled.div`
  align-items: center;
  background-color: ${toggleDarkModeStyles('tooltipOrangeBackground')};
  border-radius: 50px;
  display: flex;
  gap: 4px;
  padding: ${formatViewport(4)} ${formatViewport(16)};
  height: ${formatViewport(24, 'vh')};
`;

export const TooltipIconWrapper = styled.div`
  display: flex;

  svg {
    width: ${formatViewport(16)};
    height: ${formatViewport(16, 'vh')};

    path {
      stroke: ${toggleDarkModeStyles('tooltipOrangeIcon')};
    }
  }
`;

export const Label = styled.div`
  color: ${toggleDarkModeStyles('tooltipOrangeText')};
  font-size: ${formatViewport(12)};
  font-style: normal;
  font-weight: 500;
  line-height: ${formatViewport(16, 'vh')};
`;
