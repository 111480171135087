import { memo, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { usePopper } from 'react-popper';

import CalendarWidget from 'components/CalendarWidget';
import PdfWidget from 'components/PdfWidget';
import PerksWidget from 'components/PerksWidget/PerksWidget';
import RotatingGallery from 'components/RotatingGallery';
import TransportationWidget from 'components/TransportationWidget';
import WeatherWidget from 'components/WeatherWidget';
import WidgetDropdown from 'components/WidgetDropdown';

import { ReactComponent as ClosedIcon } from 'images/closed.svg';
import { ReactComponent as OpenedIcon } from 'images/opened.svg';

import { usePopupContext } from 'providers/PopupProvider/PopupProvider';

import { Column, ColumnOptions, Row, WidgetType } from 'shared/types';

import { useUpdateTileInSchema } from '../../hooks/useUpdateTileInSchema';
import useWidgetsContext from '../../hooks/useWidgetsContext';
import { schema as initialSchema } from '../../shared/defaultWidgetsSchema';
import Announcements from '../Announcements';

import * as Styles from './WidgetBase.styles';
import * as Types from './WidgetBase.types';

const Widget = ({
  columnOptions,
  tileNumber,
  type,
}: {
  additionalNumber?: number;
  columnOptions: ColumnOptions;
  tileNumber: [Column, Row];
  type: WidgetType;
}) => {
  switch (type) {
    case WidgetType.None:
      return <div />;
    case WidgetType.Announcements:
      return <Announcements tileNumber={tileNumber} columnOptions={columnOptions} />;
    case WidgetType.RotatingGallery:
      return <RotatingGallery tileNumber={tileNumber} columnOptions={columnOptions} />;
    case WidgetType.Transportation:
      return <TransportationWidget />;
    case WidgetType.Perks:
      return <PerksWidget />;
    case WidgetType.Pdf:
      return <PdfWidget tileNumber={tileNumber} />;
    case WidgetType.Calendar:
      return <CalendarWidget />;
    case WidgetType.Weather:
      return <WeatherWidget />;
    default:
      return <div />;
  }
};

const WidgetBase = memo(({ columnOptions, hideWidget, tileNumber, type, onOptionClicked }: Types.Props) => {
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { updateTile } = useUpdateTileInSchema();
  const { onOpen: onPopupOpen } = usePopupContext();
  const { isEditable } = useWidgetsContext();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
    ],
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [additionalNumberData, setAdditionalNumberData] = useState(0);

  const clickOutsideRef = useOnclickOutside(() => {
    setIsDropdownOpen(false);
  });

  const tileClassName = tileNumber.join('');

  if (hideWidget) {
    return null;
  }

  return (
    <Styles.Container className={`class-${tileClassName} grid-item`} columnOptions={columnOptions}>
      {isEditable ? (
        <Styles.PopperReference ref={setReferenceElement}>
          <Styles.IconContainer
            isWidgetPresent={type !== WidgetType.None}
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            ref={clickOutsideRef}
          >
            {isDropdownOpen ? <OpenedIcon /> : <ClosedIcon />}
          </Styles.IconContainer>
        </Styles.PopperReference>
      ) : null}
      {isDropdownOpen && (
        <Styles.PopperContainer ref={setPopperElement} style={{ ...styles.popper }} {...attributes.popper}>
          <div ref={clickOutsideRef}>
            <WidgetDropdown
              onOptionClicked={(type, columnOptions, deleteWidget = false, additionalNumber = 0) => {
                onOptionClicked(type, columnOptions);
                setAdditionalNumberData(additionalNumber);
                setIsDropdownOpen(false);
                onPopupOpen('imageUploaderPopup', type !== WidgetType.RotatingGallery);
                onPopupOpen('pdfsPopup', type !== WidgetType.Pdf);
                onPopupOpen('announcementsPopup', type !== WidgetType.Announcements);
                if (deleteWidget) {
                  const [column, row] = tileNumber;
                  updateTile(row, column, initialSchema[row][column]);
                }
              }}
            />
          </div>
        </Styles.PopperContainer>
      )}
      <Widget
        type={type}
        additionalNumber={additionalNumberData}
        tileNumber={tileNumber}
        columnOptions={columnOptions || ColumnOptions.HalfColumn}
      />
    </Styles.Container>
  );
});

export default WidgetBase;
