import React from 'react';
import ReactDOM from 'react-dom';

interface ModalPortalProps {
  portalName: string;
}

const ModalPortal: React.FC<ModalPortalProps> = ({ children, portalName }) =>
  ReactDOM.createPortal(children, document.getElementById(portalName)!);

const Popup: React.FC<ModalPortalProps> = ({ portalName, children }) => (
  <ModalPortal portalName={portalName}>{children}</ModalPortal>
);

export default Popup;
