import styled from 'styled-components';

import { formatViewport } from 'shared/getPixelsToViewport';
import { toggleDarkModeStyles } from 'shared/theme';
import { ColumnOptions } from 'shared/types';

import { COLUMN_HEIGHT, COLUMN_WIDTH } from './WidgetBase.constant';

export const IconContainer = styled.div<{ isWidgetPresent: boolean }>`
  ${({ isWidgetPresent }) =>
    isWidgetPresent &&
    `
  display: none;`}

  svg {
    width: ${formatViewport(51.2)};
    height: ${formatViewport(51.2)};

    circle {
      fill: ${toggleDarkModeStyles('white')};
    }

    path {
      fill: ${toggleDarkModeStyles('black')};
    }
  }
`;

export const Container = styled.div<{ columnOptions: ColumnOptions | null }>`
  height: ${({ columnOptions }) => COLUMN_HEIGHT[columnOptions ?? ColumnOptions.HalfColumn]};
  width: ${({ columnOptions }) => COLUMN_WIDTH[columnOptions ?? ColumnOptions.HalfColumn]};
  background-color: ${toggleDarkModeStyles('widgetBackground')};
  box-shadow: 0 0 4px ${toggleDarkModeStyles('whiteBoxShadow')};
  border-radius: ${formatViewport(8)};
  position: relative;
  display: flex;

  :hover ${IconContainer} {
    display: flex;
  }
`;

export const PopperReference = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10;
  :hover {
    cursor: pointer;
  }
`;

export const PopperContainer = styled.div`
  z-index: 10;
`;
