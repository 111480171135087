import urljoin from 'url-join';

import endpoints from 'shared/endpoints';

import * as Styles from './Perk.styles';
import * as Types from './Perk.types';

const Perk = ({ data }: Types.Props) => (
  <Styles.Container>
    <Styles.BackgroundImageContainer>
      <Styles.BackgroundImage src={urljoin(process.env.REACT_APP_EV_URL, endpoints.PERKS_IMAGE, data.image)} />
      <Styles.BadgeContainer>
        <Styles.BadgeImage src={urljoin(process.env.REACT_APP_EV_URL, endpoints.PERKS_IMAGE, data.logo)} />
        <Styles.BadgeLabel>{data.tag}</Styles.BadgeLabel>
      </Styles.BadgeContainer>
    </Styles.BackgroundImageContainer>
    <Styles.TextContainer>
      <Styles.Header>{data.name}</Styles.Header>
      <Styles.MainText>{data.description}</Styles.MainText>
    </Styles.TextContainer>
  </Styles.Container>
);

export default Perk;
