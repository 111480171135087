import { ListItemText, MenuItem } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';

import FormDropdown from 'components/FormDropdown';

import { parseDateTimezone } from 'shared/parseDateTimezone';

import * as CommonPopupStyles from '../../commonPopupStyles';
import * as S from '../styles';
import { Cross } from '../../../../images/svg';
import { ANNOUNCEMENT_INITIAL_VALUES } from '../../../Announcements/constants';
import { AnnouncementsFormValues } from '../../../Announcements/types';

const HandleEditAnnouncements = ({
  createdAnnouncementsList,
  onRemoveAnnouncements,
}: {
  onRemoveAnnouncements: (id: number) => void;
  createdAnnouncementsList: AnnouncementsFormValues[];
}) => {
  const formik = useFormikContext<AnnouncementsFormValues>();
  const { setValues } = formik;

  const onEditAnnouncements = (editedAnnouncementTitle: unknown) => {
    if (editedAnnouncementTitle === 'No Edit') {
      setValues({ ...ANNOUNCEMENT_INITIAL_VALUES, isEditing: false });
      return;
    }

    const editedAnnouncement = createdAnnouncementsList.find(({ title }) => title === editedAnnouncementTitle);
    if (editedAnnouncement) {
      setValues({ ...editedAnnouncement, date: parseDateTimezone(editedAnnouncement?.date), isEditing: true });
    }
  };

  const onRemoveAnnouncement = (e: React.MouseEvent<HTMLDivElement>, id: number | null) => {
    e.stopPropagation();
    if (id === null) return;
    onRemoveAnnouncements(id);
  };

  const renderOptions = () =>
    createdAnnouncementsList.map(({ title, id }) => (
      <MenuItem key={id} value={title}>
        <ListItemText primary={title} />
        {title !== 'No Edit' ? (
          <S.CrossWrapper onClick={(e) => onRemoveAnnouncement(e, id)}>
            <Cross />
          </S.CrossWrapper>
        ) : null}
      </MenuItem>
    ));

  return (
    <S.FormSection>
      <CommonPopupStyles.CommonTitle>Choose which announcement to edit</CommonPopupStyles.CommonTitle>
      <FormDropdown defaultValue="No Edit" renderOptions={renderOptions()} onChange={onEditAnnouncements} />
    </S.FormSection>
  );
};

export default HandleEditAnnouncements;
