import styled, { css } from 'styled-components';

import { colors } from 'shared/colors';
import { formatViewport } from 'shared/getPixelsToViewport';
import { toggleDarkModeStyles } from 'shared/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${formatViewport(8)};
  padding: ${formatViewport(12)};
  border: 1px solid black;
  background-color: ${toggleDarkModeStyles('black')};
`;

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  margin: ${formatViewport(2)};
  padding: ${formatViewport(6)};
  border-radius: 8px;
  color: ${toggleDarkModeStyles('dropDownText')};

  :hover {
    cursor: pointer;
    background-color: ${colors.lavenderGray};
    color: white;
  }
`;

export const OptionIcon = styled.div<{ isClicked: boolean }>`
  display: flex;
  align-items: center;
  svg {
    width: ${formatViewport(16)};
    height: ${formatViewport(18)};
  }

  path {
    ${({ isClicked }) =>
      isClicked &&
      css`
        fill: ${toggleDarkModeStyles('white')};
      `}
  }
`;

export const SizeOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${formatViewport(24)};
`;

const BaseText = styled.div`
  font-size: ${formatViewport(12.8)};
  margin-left: ${formatViewport(12)};
`;

export const OptionText = styled(BaseText)<{ isClicked: boolean }>`
  font-size: ${formatViewport(20)};
  ${({ isClicked }) =>
    isClicked &&
    css`
      font-weight: 500;
      color: ${toggleDarkModeStyles('white')};
    `}
`;

export const DeleteText = styled(BaseText)`
  color: ${colors.crimson};
`;
