import urljoin from 'url-join';

import { ReactComponent as CapacityIcon } from 'images/calendarCapacity.svg';
import { ReactComponent as CostIcon } from 'images/calendarCost.svg';
import { ReactComponent as DescriptionIcon } from 'images/calendarDescription.svg';
import { ReactComponent as PlaceIcon } from 'images/calendarPlace.svg';

import endpoints from 'shared/endpoints';

import * as Styles from './CalendarEvent.styles';
import * as Types from './CalendarEvent.types';
import * as Utils from './CalendarEvent.utils';

const CalendarEvent = ({ date, data, isFirstEvent }: Types.Props) => (
  <Styles.Container>
    {isFirstEvent && <Styles.DateLabel>{Utils.getDateLabel(date)}</Styles.DateLabel>}
    <Styles.EventContainer>
      <Styles.TimeContainer>{Utils.getTime(data.date, data.timezone)}</Styles.TimeContainer>
      <Styles.ImageContainer>
        {data.image ? (
          <Styles.Image src={urljoin(process.env.REACT_APP_EV_URL, endpoints.EVENT_IMAGE, data.image)} />
        ) : (
          <Styles.ImagePlaceholder>{data.name}</Styles.ImagePlaceholder>
        )}
      </Styles.ImageContainer>
      <Styles.InfoContainer>
        {data.image && <Styles.Header>{data.name}</Styles.Header>}
        {data.description ? (
          <Styles.SingleInfo>
            <Styles.IconContainer>
              <DescriptionIcon />
            </Styles.IconContainer>
            <Styles.DescriptionText>{data.description}</Styles.DescriptionText>
          </Styles.SingleInfo>
        ) : null}
        {data.place ? (
          <Styles.SingleInfo>
            <Styles.IconContainer>
              <PlaceIcon />
            </Styles.IconContainer>
            <Styles.Text>{`Location: ${data.place}`}</Styles.Text>
          </Styles.SingleInfo>
        ) : null}
        {data.capacity ? (
          <Styles.SingleInfo>
            <Styles.IconContainer>
              <CapacityIcon />
            </Styles.IconContainer>
            <Styles.Text>{`Capacity: ${data.taken}/${data.capacity}`}</Styles.Text>
            <Styles.AvatartsContainer>
              {data.clientAvatars.map((id, ind) => (
                <Styles.Avatar
                  key={id}
                  src={urljoin(process.env.REACT_APP_EV_URL, endpoints.AVATAR, id.split('/')?.at(-1) ?? '')}
                  ind={ind}
                />
              ))}
            </Styles.AvatartsContainer>
          </Styles.SingleInfo>
        ) : null}
        {data.cost ? (
          <Styles.SingleInfo>
            <Styles.IconContainer>
              <CostIcon />
            </Styles.IconContainer>
            <Styles.Text>{`Price: $${data.cost}`}</Styles.Text>
          </Styles.SingleInfo>
        ) : null}
      </Styles.InfoContainer>
    </Styles.EventContainer>
  </Styles.Container>
);

export default CalendarEvent;
