import styled from 'styled-components';

import { formatViewport } from 'shared/getPixelsToViewport';
import { toggleDarkModeStyles } from 'shared/theme';

export const Container = styled.div`
  padding: ${formatViewport(21)} ${formatViewport(25)} ${formatViewport(24)} ${formatViewport(20)};
`;

export const Header = styled.header`
  font-size: ${formatViewport(12)};
  line-height: ${formatViewport(30)};
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderTitle = styled.div`
  color: ${toggleDarkModeStyles('white')};
  font-size: ${formatViewport(24)};
  line-height: ${formatViewport(36)};
`;

export const HeaderLabel = styled.div`
  font-size: ${formatViewport(12)};
  line-height: ${formatViewport(14.4, 'vh')};
  letter-spacing: ${formatViewport(-0.24)};
  color: ${toggleDarkModeStyles('white')};
  margin-top: ${formatViewport(20, 'vh')};
  margin-bottom: ${formatViewport(8, 'vh')};
`;

export const TransportWrapper = styled.div`
  display: flex;
  gap: ${formatViewport(17.5)};
  justify-content: space-between;
  height: 100%;
`;

export const TransportInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;

  &:first-of-type {
    flex: 1;
  }

  & header {
    font-size: ${formatViewport(12)};
    line-height: ${formatViewport(14.4)};
    letter-spacing: ${formatViewport(-0.24)};

    margin-bottom: ${formatViewport(22)};
  }

  & main {
    display: flex;
    flex-direction: column;
    gap: ${formatViewport(10)};
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: ${formatViewport(56, 'vh')};
`;

export const Col1 = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${formatViewport(16)};
`;

export const TransitIcon = styled.div`
  line-height: 120%;
  svg {
    path {
      fill: ${toggleDarkModeStyles('white')};
    }
  }
`;

export const StationName = styled.div`
  font-weight: 600;
  letter-spacing: ${formatViewport(-0.24)};
  font-size: ${formatViewport(12)};
  color: white;
  border-radius: 100%;
  width: ${formatViewport(32)};
  height: ${formatViewport(32)};
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TransitText = styled.div`
  font-size: ${formatViewport(16)};
  color: ${toggleDarkModeStyles('white')};
`;

export const TransitIconsGroup = styled.div`
  display: flex;
  gap: ${formatViewport(5)};

  max-width: ${formatViewport(52 * 4 + 15)};
  overflow: hidden;

  & div {
    padding: ${formatViewport(10)};

    svg {
      width: ${formatViewport(30)};
      height: ${formatViewport(25)};
    }
  }
`;

export const RowArrivesText = styled(Row)`
  font-size: ${formatViewport(24)};
  font-weight: 600;
  line-height: 120%;
  letter-spacing: ${formatViewport(-0.24)};
  color: ${toggleDarkModeStyles('white')};
  display: flex;
  justify-content: center;
`;
