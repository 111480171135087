import React from 'react';

import AspectRationTooltip from '../Tooltips/AspectRationTooltip';
import PhotoPreview from './components/PhotoPreview';
import { useUploadImagesDropzone } from './hooks';
import { UploadImageFile, UploadImagesProps } from './types';

import * as S from './UploadImages.styles';

const UploadImages = ({
  recommendedAspectRation,
  files,
  title = 'Add file',
  setFiles,
  onRemoveFiles,
}: UploadImagesProps) => {
  const { getRootProps, getInputProps, open } = useUploadImagesDropzone({ files, setFiles });

  const onPhotoRemove = (file: UploadImageFile) => {
    onRemoveFiles(files.filter((f) => f.preview === file.preview));
    setFiles(files.filter((f) => f.preview !== file.preview));
  };

  return (
    <S.ContentWrapper>
      <S.CommonTitle>
        {title}
        <S.AddPhotosButton onClick={open}>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 0V16M0 8H16" />
          </svg>
        </S.AddPhotosButton>
        <AspectRationTooltip recommendedAspectRation={recommendedAspectRation} />
      </S.CommonTitle>
      <S.ContentDialogWindow {...getRootProps()}>
        <input {...getInputProps()} />
        <S.Photos>
          {files.map((file) => (
            <PhotoPreview key={file.preview} file={file} onRemove={onPhotoRemove} />
          ))}
        </S.Photos>
        <S.Placeholder>
          {files.length === 0 ? 'Click to open the dialog window or drag files here' : null}
        </S.Placeholder>
      </S.ContentDialogWindow>
    </S.ContentWrapper>
  );
};

export default UploadImages;
