import { ReactNode } from 'react';

import { ReactComponent as FullColumnIcon } from 'images/column/fullcolumn.svg';
import { ReactComponent as HalfColumnIcon } from 'images/column/halfcolumn.svg';
import { ReactComponent as HorizontalColumnIcon } from 'images/column/horizontalcolumn.svg';
import { ReactComponent as SquareColumnIcon } from 'images/column/squarecolumn.svg';
import { ReactComponent as DeleteIcon } from 'images/delete.svg';

import * as S from './styles';
import { DropdownButtonProps, DropdownIcons } from './types';

const DropdownColumnButton = ({ icon, text, onSelect }: DropdownButtonProps) => {
  const renderIcon = (columnOption: DropdownIcons | undefined): ReactNode => {
    switch (columnOption) {
      case DropdownIcons.FullColumn:
        return <FullColumnIcon />;
      case DropdownIcons.HalfColumn:
        return <HalfColumnIcon />;
      case DropdownIcons.SquareColumn:
        return <SquareColumnIcon />;
      case DropdownIcons.HorizontalColumn:
        return <HorizontalColumnIcon />;
      case DropdownIcons.Delete:
        return <DeleteIcon />;
      default:
        return undefined;
    }
  };
  return (
    <S.OptionContainer onClick={onSelect}>
      <S.IconWrapper>{renderIcon(icon)}</S.IconWrapper>
      <S.ColumnOptionText>{text || icon}</S.ColumnOptionText>
    </S.OptionContainer>
  );
};

export default DropdownColumnButton;
