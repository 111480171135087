import { OutlinedInput, SelectChangeEvent } from '@mui/material';
import React from 'react';

import * as S from './styles';
import { FormDropdownProps } from './types';

const FormDropdown = ({ renderOptions, onChange, ...muiDropdownProps }: FormDropdownProps) => {
  const [open, setOpen] = React.useState(false);

  const onSelectChange = (e: SelectChangeEvent<unknown>) => {
    onChange(e.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const renderValue = (value: unknown) =>
    muiDropdownProps?.renderValue || (
      <S.RenderedValue>
        <>{value}</>
      </S.RenderedValue>
    );

  return (
    <S.Dropdown
      IconComponent={() => (
        <S.ArrowIconWrapper onClick={handleOpen}>
          <S.ArrowIcon isOpen={open} />
        </S.ArrowIconWrapper>
      )}
      input={<OutlinedInput />}
      open={open}
      renderValue={renderValue}
      onClose={handleClose}
      onOpen={handleOpen}
      {...muiDropdownProps}
      onChange={onSelectChange}
    >
      {renderOptions}
    </S.Dropdown>
  );
};

export default FormDropdown;
